import React from 'react';
import './Services.css';
import easyParticipation from '../../../pic/easy-participation.png'
import insightsDashboard from '../../../pic/insights-dashboard.png'
import expertRecommandation from '../../../pic/expert-recommandation.png'
import dailyTracking from '../../../pic/daily-tracking.png'
import personalInsights from '../../../pic/personal-insights.png'
import communitySupport from '../../../pic/community-support.png'
const servicesData = [
    {
        title: 'Easy Participation',
        description: 'Join our community and contribute to our chronotype research through simple daily surveys.',
        icon: easyParticipation
    },
    {
        title: 'Insights Dashboard',
        description: 'Access your personal dashboard to view detailed insights and trends about your sleep and productivity patterns.',
        icon: insightsDashboard
    },
    {
        title: 'Expert Recommendations',
        description: 'Receive personalized tips and strategies from sleep and productivity experts based on your chronotype.',
        icon: expertRecommandation
    },
    {
        title: 'Daily Tracking',
        description: 'Monitor your sleep patterns and productivity.',
        icon: dailyTracking
    },
    {
        title: 'Personalized Insights',
        description: 'Get tailored advice based on your unique chronotype.',
        icon: personalInsights
    },
    {
        title: 'Community Support',
        description: 'Connect with others who share your chronotype and exchange tips and experiences to enhance your daily routine.',
        icon: communitySupport
    },
];


const Services = () => {
    return (
        <section className="services-section">
            <h2 className="services-title">Services we Offer</h2>
            <p className="services-subtitle">
                Our survey helps you track your wake and sleep times and evaluate your daily productivity.
                By understanding your natural rhythms, you can improve your overall well-being.
            </p>
            <div className="services-grid">
                {servicesData.map((service, index) => (
                    <div key={index} className="service-card">
                        <div className="service-icon">
                            <img src={service.icon} alt={service.title} />
                        </div>
                        <h3 className="service-title">{service.title}</h3>
                        <p className="service-description">{service.description}</p>
                    </div>
                ))}
            </div>
            <button className="learn-more-button">Learn more</button>
        </section>
    );
};

export default Services;
