import axios from 'axios';
import { getToken } from './authService';

const API_URL = process.env.REACT_APP_API_URL + '/survey/';

export const saveSurveyResponse = async (userId, responses) => {
    const token = getToken();
    if (!token) {
        throw new Error('Authentication token is missing. Please log in.');
    }

    try {
        const res = await axios.post(`${API_URL}save-response`, { userId, responses }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return res;
    } catch (error) {
        console.error('Error saving survey responses:', error);
        throw error;
    }
};

export const completeSurvey = async (userId) => {
    const token = getToken();
    if (!token) {
        throw new Error('Authentication token is missing. Please log in.');
    }

    try {
        const res = await axios.post(`${API_URL}complete-survey`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return res;
    } catch (error) {
        console.error('Error completing survey:', error);
        throw error;
    }
};

const hasNonNullValues = (obj) => {
    for (let key in obj) {
        if (obj[key] === null || obj[key] === undefined) {
            return false;
        }
    }
    return true;
};

export const checkSurveyCompletion = async (userId) => {
    const token = getToken();
    if (!token) {
        throw new Error('Authentication token is missing. Please log in.');
    }

    try {
        const response = await axios.get(`${API_URL}get-all-responses/${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        const responses = response.data;
        console.log(responses);

        // Check if the number of keys in the responses object is 19 or more
        // and ensure no nested values are null
        const isSurveyComplete = Object.keys(responses).length >= 20 &&
            Object.values(responses).every(value => hasNonNullValues(value));

        return isSurveyComplete;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            console.warn('No responses found for this user. Survey is not completed.');
            return false; // Treat 404 as survey not completed
        } else {
            console.error('Error checking survey completion:', error);
            throw error; // Re-throw other errors
        }
    }
};


export const fetchSurveyResponses = async (userId) => {
    const token = getToken();
    if (!token) {
        throw new Error('Authentication token is missing. Please log in.');
    }

    try {
        const response = await axios.get(`${API_URL}get-all-responses/${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching survey responses:', error);
        throw error;
    }
};


export const fetchSurveyCreatedAt = async (userId) => {
    const token = getToken();
    if (!token) {
        throw new Error('Authentication token is missing. Please log in.');
    }

    try {
        const response = await axios.get(`${API_URL}get-survey-created-at/${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data.createdAt)

        return response.data;
    } catch (error) {
        console.error('Error fetching survey createdAt:', error);
        throw error;
    }
};

export const fetchQ20Data = async (userId) => {
    const token = getToken();
    if (!token) {
        throw new Error('Authentication token is missing. Please log in.');
    }

    try {
        const response = await axios.get(`${API_URL}${userId}/workingStartAt`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        // Assuming the response data contains startTime and duration
        return response.data;
    } catch (error) {
        console.error('Error fetching q20 data:', error);
        throw error;
    }
};