import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import uflowIcon from '../../../pic/uflowIcon.png';

function Header({ fixed }) {
    return (
        <header className={`header ${fixed ? 'header-fixed' : ''}`}>
            <div className="logo">
                <img src={uflowIcon} alt="UFLOW Logo" />
                <span>UFLOW</span>
            </div>
            <nav>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    {/* <li><Link to="/contact">Contact Us</Link></li> */}
                    <li><Link to="/login">Login</Link></li>
                    {/* <li><Link to="/about">About us</Link></li> */}
                </ul>
            </nav>
        </header>
    );
}

export default Header;
