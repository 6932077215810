import React, { useState } from 'react';
import { TextField, Button, Card, IconButton, InputAdornment } from '@mui/material';
import { toast } from 'react-toastify';
import { resetPassword } from '../../../services/authService';
import { useParams, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import './ResetPassword.css';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for toggling confirm password visibility
    const { token } = useParams();
    const navigate = useNavigate();

    const handleInputChange = (event, setState) => {
        setState(event.target.value);
    };

    const handleResetPassword = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            toast.error('Passwords do not match.');
            return;
        }
        try {
            await resetPassword(token, password);
            toast.success('Password reset successfully.');
            navigate('/login');
        } catch (error) {
            console.error('Error resetting password:', error);
            toast.error('Failed to reset password.');
        }
    };

    const toggleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    };

    return (
        <Card className="reset-password-card">
            <form onSubmit={handleResetPassword}>
                <TextField
                    label="New Password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => handleInputChange(e, setPassword)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    className="reset-password-input"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={toggleShowPassword} edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    label="Confirm Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={(e) => handleInputChange(e, setConfirmPassword)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    className="reset-password-input"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={toggleShowConfirmPassword} edge="end">
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button type="submit" variant="contained" className="reset-password-button">
                    Reset Password
                </Button>
            </form>
        </Card>
    );
};

export default ResetPassword;
