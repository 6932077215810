import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translations
import enTranslation from './locales/en/translation.json';
import deTranslation from './locales/de/translation.json';

i18n
    .use(initReactI18next) // Integrates with React
    .init({
        resources: {
            en: {
                translation: enTranslation
            },
            de: {
                translation: deTranslation
            }
        },
        lng: 'en', // default language
        fallbackLng: 'en', // if translation not available, fallback to this language
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;