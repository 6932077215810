import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Box, IconButton, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { format, isWithinInterval, isWeekend } from 'date-fns';
import { getPerformanceResponses } from '../../../../services/performanceService';
import { getScheduledNotificationForObservationPeriod } from '../../../../services/notificationsService';
import './SurveyProgress.css';
import moment from 'moment-timezone';

const SurveyCalendar = ({ userId, onReturn }) => {
    const [performanceData, setPerformanceData] = useState({});
    const [observationPeriods, setObservationPeriods] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const timeZone = 'Europe/Berlin';

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch observation periods and performance responses
                const [scheduledNotifications, response] = await Promise.all([
                    getScheduledNotificationForObservationPeriod(userId),
                    getPerformanceResponses(userId)
                ]);

                if (!scheduledNotifications || !scheduledNotifications.dateRanges.length) {
                    throw new Error("Invalid data format from getScheduledNotificationForObservationPeriod API");
                }

                if (!response || !response.data || !Array.isArray(response.data)) {
                    throw new Error("Invalid data format from getPerformanceResponses API");
                }

                const periods = scheduledNotifications.dateRanges.map((range) => ({
                    startDate: new Date(range.startDate),
                    endDate: new Date(range.endDate),
                    notifications: range.notifications.map(notif => moment(notif.notificationTime).tz(timeZone)),
                }));

                setObservationPeriods(periods);

                // Map performance data by date, considering only the hour
                const entriesByDate = response.data.reduce((acc, entry) => {
                    const entryDateTime = moment(entry.timestamp).tz(timeZone);
                    const entryDate = format(entryDateTime.toDate(), 'yyyy-MM-dd');
                    const entryHour = entryDateTime.hour();

                    // Check if entry falls within any observation period, is not on a weekend, and matches the hour
                    const relevantPeriod = periods.find(period =>
                        isWithinInterval(entryDateTime.toDate(), { start: period.startDate, end: period.endDate })
                    );

                    if (relevantPeriod && !isWeekend(entryDateTime.toDate())) {
                        const hasMatchingNotification = relevantPeriod.notifications.some(notif => notif.hour() === entryHour);

                        if (hasMatchingNotification) {
                            acc[entryDate] = (acc[entryDate] || 0) + 1;
                        }
                    }

                    return acc;
                }, {});

                setPerformanceData(entriesByDate);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [userId, timeZone]);

    const getTileClassName = ({ date, view }) => {
        if (view === 'month') {
            const formattedDate = format(date, 'yyyy-MM-dd');

            // Check if the date is within any observation period and is not a weekend
            const isInObservationPeriod = observationPeriods.some(period =>
                isWithinInterval(date, { start: period.startDate, end: period.endDate })
            );

            if (!isInObservationPeriod || isWeekend(date)) {
                return null; // No custom class if it's outside observation period or a weekend
            }

            // Determine the color based on entry count
            const entryCount = performanceData[formattedDate] || 0;
            const notificationsForDate = observationPeriods.flatMap(period =>
                period.notifications.filter(notif => format(notif.toDate(), 'yyyy-MM-dd') === formattedDate)
            );
            console.log(notificationsForDate)



            const expectedCount = notificationsForDate.length

            if (entryCount === 0) {
                return 'red-day'; // No records
            } else if (entryCount < expectedCount) {
                return 'blue-day'; // At least one record, but fewer than expected
            } else {
                return 'green-day'; // Expected or more records
            }
        }
    };

    const handleDateClick = (date) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        const entryCount = performanceData[formattedDate] || 0;

        // Find notifications and expected count for the selected date
        const notificationsForDate = observationPeriods.flatMap(period =>
            period.notifications.filter(notif => format(notif.toDate(), 'yyyy-MM-dd') === formattedDate)
        );

        const expectedCount = notificationsForDate.length; // No extra check for the first day

        setSelectedDate({ date: formattedDate, count: entryCount, expected: expectedCount });
    };


    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, padding: 4 }}>
            {/* Header with Return Icon and Smaller Text */}
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: 2 }}>
                <IconButton onClick={onReturn} sx={{ marginRight: 1 }}>
                    <ArrowBack />
                </IconButton>
                <Typography variant="body2">
                    {selectedDate
                        ? `Total performance questions answered on ${selectedDate.date}: ${selectedDate.count}/${selectedDate.expected}`
                        : "Survey Progress"}
                </Typography>
            </Box>

            {/* Calendar Component with onClickDay */}
            <Calendar
                tileClassName={getTileClassName}
                onClickDay={handleDateClick} // Capture date clicks
            />
        </Box>
    );
};

export default SurveyCalendar;
