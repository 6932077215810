import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserProfile, updateUserProfile } from '../../../services/userService';
import { saveSurveyResponse, completeSurvey } from '../../../services/surveyService';
import { Toolbar } from '@mui/material';
import PersonalInfo from '../Share/PersonalInfo/PersonalInfo';
import SurveyIntroduction from './ServeyQuestions/SurveyIntroduction';
import Q1 from './ServeyQuestions/Q1';
import Q2 from './ServeyQuestions/Q2';
import Q3 from './ServeyQuestions/Q3';
import Q4 from './ServeyQuestions/Q4';
import Q5 from './ServeyQuestions/Q5';
import Q6 from './ServeyQuestions/Q6';
import Q7 from './ServeyQuestions/Q7';
import Q8 from './ServeyQuestions/Q8';
import Q9 from './ServeyQuestions/Q9';
import Q10 from './ServeyQuestions/Q10';
import Q11 from './ServeyQuestions/Q11';
import Q12 from './ServeyQuestions/Q12';
import Q13 from './ServeyQuestions/Q13';
import Q14 from './ServeyQuestions/Q14';
import Q15 from './ServeyQuestions/Q15';
import Q16 from './ServeyQuestions/Q16';
import Q17 from './ServeyQuestions/Q17';
import Q18 from './ServeyQuestions/Q18';
import Q19 from './ServeyQuestions/Q19';
import workingTime from './ServeyQuestions/workingTime'
import Result from './ServeyQuestions/Result';
import PerformanceQuestion from './DailySurveyAndPerformanceQuestion/PerformanceQuestion/PerformanceQuestion';
import '../Dashboard.css';
import DailySurveyComponent from './DailySurveyAndPerformanceQuestion/DailySurvey/DailySurvey';
import CalendarComponent from './Calendar/Calendar';
import Overview from '../Share/Overview/Overview';
import { getDailyQuestionResponses } from '../../../services/dailyQuestionService';
import { getPerformanceResponses } from '../../../services/performanceService';
import { UserContext } from '../../../contexts/UserContext';

const UserDashboard = ({ content, setContent, onAnswerQuestion, onSurveyCompletion }) => {
    const [surveyCompleted, setSurveyCompleted] = useState(false);
    const [responses, setResponses] = useState({});
    const [loading, setLoading] = useState(true);
    const { user, setUser } = useContext(UserContext);
    const [dailySurveyResponses, setDailySurveyResponses] = useState([]);
    const [performanceResponses, setPerformanceResponses] = useState([]);
    const [noData, setNoData] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedUser = JSON.parse(localStorage.getItem('user'));
                const token = storedUser?.token;

                if (!token) {
                    navigate('/login'); // Redirect to login if no token is found
                    return;
                }

                // If the user data is already in context, no need to fetch again
                if (user && user._id) {
                    setLoading(false);
                    return;
                }

                const userProfile = await getUserProfile(token);
                setUser(userProfile);

                // Save the updated user profile to localStorage
                const completeUser = { ...userProfile, token };
                localStorage.setItem('user', JSON.stringify(completeUser));

                setLoading(false); // Set loading to false after fetching data
            } catch (error) {
                console.error("Error fetching user profile or checking survey completion:", error);
                setLoading(false); // Set loading to false even if there's an error
            }
        };

        fetchData();
    }, [setUser, navigate]);

    const handleUpdateUserInfo = async (updatedData) => {
        try {
            const updatedUser = await updateUserProfile(updatedData);
            const storedUser = JSON.parse(localStorage.getItem('user'));
            const completeUser = { ...updatedUser, token: storedUser.token };
            setUser(completeUser);
            localStorage.setItem('user', JSON.stringify(completeUser));
            window.location.reload();
        } catch (error) {
            console.error("Error updating user info:", error);
            alert('Failed to update user info. Please check the console for more information.');
        }
    };

    const handleMenuItemClick = (item) => {
        setContent(item);
    };


    useEffect(() => {
        const fetchDailyResponses = async () => {
            if (user && user._id) {
                try {
                    const dailyResponse = await getDailyQuestionResponses(user._id);
                    setDailySurveyResponses(dailyResponse.data || []); // Ensure it always sets an array
                } catch (error) {
                    if (error.response && error.response.status === 404) {
                        // Handle the case where no daily responses exist (404)
                        setDailySurveyResponses([]); // Set to an empty array
                    } else {
                        console.error('Error fetching daily responses:', error);
                        // Optionally, you can set an error state here if needed
                    }
                }
            }
        };

        const fetchPerformanceResponses = async () => {
            if (user && user._id) {
                try {
                    const performanceResponse = await getPerformanceResponses(user._id);
                    setPerformanceResponses(performanceResponse.data || []); // Ensure it always sets an array
                } catch (error) {
                    if (error.response && error.response.status === 404) {
                        // Handle the case where no performance responses exist (404)
                        setPerformanceResponses([]); // Set to an empty array
                    } else {
                        console.error('Error fetching performance responses:', error);
                        // Optionally, you can set an error state here if needed
                    }
                }
            }
        };

        fetchDailyResponses();
        fetchPerformanceResponses();
    }, [user]);



    const handleNavigation = (direction) => {
        const currentIndex = questionComponents.findIndex(q => q.name === content);
        const nextIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;
        if (nextIndex >= 0 && nextIndex < questionComponents.length) {
            setContent(questionComponents[nextIndex].name);
        } else if (direction === 'next' && nextIndex === questionComponents.length) {
            handleSurveyFinish();
        }
    };

    const handleSurveyFinish = async () => {
        try {
            if (!user || !user.token) {
                throw new Error('No token found in localStorage');
            }

            const res = await saveSurveyResponse(user._id, responses);
            if (res.status === 200) {
                const completeSurveyResponse = await completeSurvey(user._id);
                if (completeSurveyResponse.status === 200) {
                    alert('Survey completed!');
                    setSurveyCompleted(true);
                    onSurveyCompletion();
                    setContent('result');
                } else {
                    alert('Failed to complete survey.');
                }
            } else {
                alert('Failed to save survey responses.');
            }
        } catch (error) {
            console.error('Error saving survey responses:', error);
            alert('An error occurred while saving survey responses. Please try again.');
        }
    };

    const saveResponse = async (question, response) => {
        console.log(`Saving response for ${question}:`, response);
        try {
            const res = await saveSurveyResponse(user._id, { [question]: response });
            if (res.status === 200) {
                setResponses(prevResponses => ({
                    ...prevResponses,
                    [question]: response
                }));
                console.log('Response saved successfully');
            } else {
                console.error('Failed to save response');
            }
        } catch (error) {
            console.error('Error saving response:', error);
        }
    };

    const fetchData = async () => {
        try {
            const storedUser = JSON.parse(localStorage.getItem('user'));
            const token = storedUser?.token;

            if (!token) {
                navigate('/login'); // Redirect to login if no token is found
                return;
            }

            // Fetch user profile if not already in context
            if (!user || !user._id) {
                const userProfile = await getUserProfile(token);
                setUser(userProfile);

                // Save the updated user profile to localStorage
                const completeUser = { ...userProfile, token };
                localStorage.setItem('user', JSON.stringify(completeUser));
            }

            // Fetch Daily Survey Responses
            const dailyResponse = await getDailyQuestionResponses(user._id);
            setDailySurveyResponses(dailyResponse.data || []);

            // Fetch Performance Responses
            const performanceResponse = await getPerformanceResponses(user._id);
            setPerformanceResponses(performanceResponse.data || []);

            setLoading(false); // Set loading to false after fetching data
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false); // Set loading to false even if there's an error
        }
    };

    const handleRefresh = () => {
        setLoading(true);  // Optionally set loading state to true
        fetchData();       // Re-fetch data when refresh is triggered
    };
    const questionComponents = [
        { name: 'surveyIntroduction', component: SurveyIntroduction },
        { name: 'sliderQuestion', component: Q1 },
        { name: 'sliderQuestion2', component: Q2 },
        { name: 'sliderQuestion3', component: Q3 },
        { name: 'sliderQuestion4', component: Q4 },
        { name: 'sliderQuestion5', component: Q5 },
        { name: 'sliderQuestion6', component: Q6 },
        { name: 'sliderQuestion7', component: Q7 },
        { name: 'sliderQuestion8', component: Q8 },
        { name: 'sliderQuestion9', component: Q9 },
        { name: 'sliderQuestion10', component: Q10 },
        { name: 'sliderQuestion11', component: Q11 },
        { name: 'sliderQuestion12', component: Q12 },
        { name: 'sliderQuestion13', component: Q13 },
        { name: 'sliderQuestion14', component: Q14 },
        { name: 'sliderQuestion15', component: Q15 },
        { name: 'sliderQuestion16', component: Q16 },
        { name: 'sliderQuestion17', component: Q17 },
        { name: 'sliderQuestion18', component: Q18 },
        { name: 'sliderQuestion19', component: Q19 },
        { name: 'sliderQuestion20', component: workingTime },
    ];

    const CurrentComponent = questionComponents.find(q => q.name === content)?.component;

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="dashboard-content">
            <div className="content">
                <Toolbar />
                <div style={{ textAlign: 'center', width: '100%', marginTop: '-100px' }}>
                    {user && CurrentComponent && <CurrentComponent user={user} userId={user._id} onNext={() => handleNavigation('next')} onPrevious={() => handleNavigation('previous')} saveResponse={saveResponse} onUpdate={handleUpdateUserInfo} />}
                    {user && content === 'personalInfo' && <PersonalInfo user={user} onUpdate={handleUpdateUserInfo} />}
                    {user && content === 'result' && <Result userId={user._id} />}
                    {user && content === 'performanceQuestion' && <PerformanceQuestion userId={user._id} />}
                    {user && content === 'DailySurvey' && <DailySurveyComponent userId={user._id} />}
                    {user && content === 'calendar' && <CalendarComponent userId={user._id} />}
                    {user && content === 'overview' && (
                        <Overview
                            userId={user._id}
                            setContent={setContent}
                            dailySurveyResponses={dailySurveyResponses}
                            performanceResponses={performanceResponses}
                            responses={responses}
                            noData={noData}
                            refreshData={handleRefresh}
                        />
                    )}
                    {!user && <p>Loading...</p>}
                </div>
            </div>
        </div>
    );
};

export default UserDashboard;
