import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Q1 = ({ userId, onNext, onPrevious, saveResponse }) => {
    const [value, setValue] = useState(5);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchExistingResponse = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                if (!user || !user.token) {
                    throw new Error('No token found in localStorage');
                }
                const token = user.token;
                const baseUrl = process.env.REACT_APP_API_URL;

                const response = await axios.get(`${baseUrl}/survey/get-response/q1/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (response.data && response.data.time) {
                    const hours = parseInt(response.data.time.split(':')[0]);
                    const minutes = parseInt(response.data.time.split(':')[1].split(' ')[0]);
                    const period = response.data.time.split(' ')[1];
                    const timeInHours = period === 'pm' && hours !== 12 ? hours + 12 : hours;
                    const time = timeInHours + minutes / 60;

                    setValue(time);
                }
            } catch (error) {
                console.error('Error fetching existing response:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchExistingResponse();
    }, [userId]);

    const getScore = (time) => {
        if (time >= 5 && time < 6.5) return 5;
        if (time >= 6.5 && time < 7.75) return 4;
        if (time >= 7.75 && time < 9) return 3;
        if (time >= 9 && time < 10.5) return 2;
        if (time >= 10.5 && time <= 12) return 1;
        return 0;
    };

    const formatTime = (time) => {
        const hours = Math.floor(time);
        const minutes = (time % 1) * 60;
        const period = hours < 12 ? 'am' : 'pm';
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        return `${formattedHours}:${minutes === 0 ? '00' : minutes} ${period}`;
    };

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const handleNext = () => {
        const time = formatTime(value);
        const score = getScore(value);
        saveResponse('q1', { time, score });
        onNext();
    };

    if (loading) {
        return <p>{t('questions.q1.loading')}</p>;
    }

    return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
            <h3>{t('questions.q1.title')}</h3>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span>5:00 am</span>
                <Slider
                    min={5}
                    max={12}
                    marks={{
                        5: '5:00 am',
                        6: '6:00 am',
                        7: '7:00 am',
                        8: '8:00 am',
                        9: '9:00 am',
                        10: '10:00 am',
                        11: '11:00 am',
                        12: '12:00 pm'
                    }}
                    step={0.25}
                    value={value}
                    onChange={handleChange}
                    style={{ width: 400, margin: '0 10px' }}
                />
                <span>12:00 pm</span>
            </div>
            <p>{t('questions.q1.selectedTime')}: {formatTime(value)} ({t('questions.q1.score')}: {getScore(value)})</p>
            <button onClick={onPrevious} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}>{t('questions.q1.previous')}</button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button onClick={handleNext} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}>{t('questions.q1.next')}</button>
        </div>
    );
};

export default Q1;
