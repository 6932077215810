import React from 'react';
import './HeroSection.css';
import doctorImage from '../../../pic/doctor-image.png';
import { useNavigate } from 'react-router-dom';

function HeroSection() {
    const navigate = useNavigate();

    const handleRegisterClick = () => {
        navigate('/login');
    };

    return (
        <section className="hero-section">
            <div className="hero-content">
                <h1 style={{ fontFamily: 'Josefin Sans, sans-serif' }}>Discover Your Natural Rhythm</h1>
                <p style={{ fontFamily: 'Josefin Sans, sans-serif' }}>Take our daily survey to understand your chronotype and optimize your productivity. Take part in our study and experience how time-sensitive work design affects your productivity and well-being.</p>
                <button
                    style={{ fontFamily: 'Josefin Sans, sans-serif' }}
                    className="register-button"
                    onClick={handleRegisterClick}
                >
                    Register Now
                </button>
            </div>
            <div className="hero-image">
                <img src={doctorImage} alt="Doctor" />
            </div>
        </section>
    );
}

export default HeroSection;
