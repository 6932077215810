import React from 'react';
import PersonalInfo from '../Share/PersonalInfo/PersonalInfo';
import Result from '../UserDashboard/ServeyQuestions/Result';

const UserDetail = ({ user, editable }) => {
    return (
        <div>
            <PersonalInfo user={user} editable={false} />  {/* Read-only mode */}
            <Result userId={user._id} />
        </div>
    );
};

export default UserDetail;
