import React, { useEffect, useState } from 'react';
import { getPerformanceResponses } from '../../../services/performanceService';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend } from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import { startOfWeek, endOfWeek, subWeeks, addWeeks } from 'date-fns';

ChartJS.register(CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);

const UserPerformance = ({ user }) => {
    const [dataPoints, setDataPoints] = useState([]);
    const [currentWeek, setCurrentWeek] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));

    useEffect(() => {
        fetchResponses();
    }, [user._id, currentWeek]);

    const fetchResponses = async () => {
        try {
            const res = await getPerformanceResponses(user._id);
            const responsesArray = res.data; // Directly use the data array

            const points = processResponsesToDataPoints(responsesArray);
            setDataPoints(points);
        } catch (error) {
            console.error('Error fetching performance responses:', error);
        }
    };

    const processResponsesToDataPoints = (responses) => {
        const points = [];
        const weekStart = startOfWeek(currentWeek, { weekStartsOn: 1 });
        const weekEnd = endOfWeek(currentWeek, { weekStartsOn: 1 });

        responses.forEach(response => {
            if (!response.score || !response.timestamp) {
                return; // Skip if score or timestamp is missing
            }

            const date = new Date(response.timestamp);

            // Filter responses to only include those within the current week
            if (date >= weekStart && date <= weekEnd) {
                const day = date.getDay(); // 0 (Sunday) to 6 (Saturday)
                const hour = date.getHours();

                // Only include Monday (1) to Friday (5) and 8AM (8) to 5PM (17)
                if (day >= 1 && day <= 5 && hour >= 8 && hour <= 17) {
                    const dayIndex = day - 1; // Monday = 0, Friday = 4
                    const hourIndex = hour - 8; // 8AM = 0, 5PM = 9
                    points.push({
                        x: hourIndex,
                        y: dayIndex,
                        r: 10, // Radius for the point, can be adjusted
                        score: response.score,
                    });
                }
            }
        });

        return points;
    };

    const data = {
        datasets: [
            {
                label: 'Performance Score',
                data: dataPoints,
                backgroundColor: (ctx) => {
                    const value = ctx.raw ? ctx.raw.score : 0; // Safely access score
                    return value ? `rgba(31, 109, 162, ${value / 10})` : 'rgba(255, 255, 255, 0.1)'; // Use alpha to represent intensity
                },
            },
        ],
    };

    const options = {
        scales: {
            x: {
                type: 'linear',
                position: 'bottom',
                title: {
                    display: true,
                    text: 'Time of Day',
                },
                ticks: {
                    callback: (value) => {
                        const timeLabels = ['8 AM', '9 AM', '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM'];
                        return timeLabels[value] || '';
                    },
                },
            },
            y: {
                type: 'linear',
                title: {
                    display: true,
                    text: 'Day of the Week',
                },
                ticks: {
                    callback: (value) => {
                        const dayLabels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
                        return dayLabels[value] || '';
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: 'Performance Score Heatmap',
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const score = context.raw ? context.raw.score : 0;
                        return `Score: ${score}`;
                    }
                }
            },
        },
        elements: {
            point: {
                radius: (ctx) => {
                    const value = ctx.raw ? ctx.raw.score : 0; // Safely access score
                    return value ? (value / 10) * 15 : 5; // Adjust point size based on score
                },
            },
        },
    };

    const handlePreviousWeek = () => {
        setCurrentWeek(subWeeks(currentWeek, 1));
    };

    const handleNextWeek = () => {
        setCurrentWeek(addWeeks(currentWeek, 1));
    };

    const handleCurrentWeek = () => {
        setCurrentWeek(startOfWeek(new Date(), { weekStartsOn: 1 }));
    };

    return (
        <div>
            <h3>Performance Heatmap for {user.firstname} {user.lastname}</h3>
            <div>
                <button onClick={handlePreviousWeek}>Previous Week</button> &nbsp;
                <button onClick={handleCurrentWeek}>Current Week</button> &nbsp;
                <button onClick={handleNextWeek}>Next Week</button>
            </div>
            <Scatter data={data} options={options} width={150} height={50} />
        </div>
    );
};

export default UserPerformance;
