import React, { useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Q18 = ({ userId, onNext, onPrevious, saveResponse }) => {
    const [value, setValue] = useState(8);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    const scores = [
        { range: [0, 5], score: 1 },
        { range: [5, 6], score: 5 },
        { range: [6, 7], score: 4 },
        { range: [7, 8], score: 3 },
        { range: [8, 9], score: 2 },
        { range: [9, 15], score: 1 },
        { range: [15, 20], score: 2 },
        { range: [20, 22], score: 1 },
        { range: [22, 24], score: 1 },
    ];

    useEffect(() => {
        const fetchExistingResponse = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                if (!user || !user.token) {
                    throw new Error('No token found in localStorage');
                }
                const token = user.token;

                const baseUrl = process.env.REACT_APP_API_URL;

                const response = await axios.get(`${baseUrl}/survey/get-response/q18/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.data && response.data.responses.q18 !== undefined) {
                    setValue(response.data.responses.q18.time);
                }
            } catch (error) {
                console.error('Error fetching existing response:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchExistingResponse();
    }, [userId]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleNext = async () => {
        const selectedTime = formatHour(value);
        const score = calculateScore(value);
        try {
            await saveResponse('q18', { time: selectedTime, score: score });
            onNext();
        } catch (error) {
            console.error('Error saving response:', error);
        }
    };

    const formatHour = hour => `${hour}:00`;

    const calculateScore = (selectedHour) => {
        for (const { range, score } of scores) {
            if (selectedHour >= range[0] && selectedHour < range[1]) {
                return score;
            }
        }
        return 0;
    };

    const selectedScore = calculateScore(value);

    if (loading) {
        return <p>{t('questions.q18.loading')}</p>;
    }

    return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
            <h3>{t('questions.q18.title')}</h3>
            <div style={{ width: '80%', margin: '0 auto' }}>
                <Slider
                    min={0}
                    max={24}
                    step={1}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(x) => `${formatHour(x)}`}
                    marks={[
                        { value: 0, label: '0' },
                        { value: 1, label: '1' },
                        { value: 2, label: '2' },
                        { value: 3, label: '3' },
                        { value: 4, label: '4' },
                        { value: 5, label: '5' },
                        { value: 6, label: '6' },
                        { value: 7, label: '7' },
                        { value: 8, label: '8' },
                        { value: 9, label: '9' },
                        { value: 10, label: '10' },
                        { value: 11, label: '11' },
                        { value: 12, label: '12' },
                        { value: 13, label: '13' },
                        { value: 14, label: '14' },
                        { value: 15, label: '15' },
                        { value: 16, label: '16' },
                        { value: 17, label: '17' },
                        { value: 18, label: '18' },
                        { value: 19, label: '19' },
                        { value: 20, label: '20' },
                        { value: 21, label: '21' },
                        { value: 22, label: '22' },
                        { value: 23, label: '23' },
                        { value: 24, label: '24' },
                    ]}
                    sx={{
                        '& .MuiSlider-thumb': {
                            backgroundColor: 'blue',
                        },
                        '& .MuiSlider-track': {
                            height: 10,
                            backgroundColor: 'transparent',
                            border: 'none',
                        },
                        '& .MuiSlider-rail': {
                            height: 10,
                            backgroundColor: '#ccc',
                        },
                        '& .MuiSlider-markLabel': {
                            transform: 'translateX(-50%)',
                        }
                    }}
                />
            </div>
            <p>{t('questions.q18.sliderLabel')}: {formatHour(value)}</p>
            <p>{t('questions.q18.scoreLabel')}: {selectedScore}</p>
            <button onClick={onPrevious} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}>{t('questions.q18.previous')}</button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button onClick={handleNext} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}>{t('questions.q18.next')}</button>
        </div>
    );
};

export default Q18;
