import React, { useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import axios from 'axios';
import './slider.css';
import { useTranslation } from 'react-i18next';

const Q17 = ({ userId, onNext, onPrevious, saveResponse }) => {
    const [value, setValue] = useState(10);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    const scores = [
        { range: [0, 5], score: 5 },
        { range: [5, 10], score: 4 },
        { range: [10, 15], score: 3 },
        { range: [15, 20], score: 2 },
        { range: [20, 24], score: 1 },
    ];

    useEffect(() => {
        const fetchExistingResponse = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                if (!user || !user.token) {
                    throw new Error('No token found in localStorage');
                }
                const token = user.token;

                const baseUrl = process.env.REACT_APP_API_URL;

                const response = await axios.get(`${baseUrl}/survey/get-response/q17/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.data && response.data.responses.q17 !== undefined) {
                    setValue(response.data.responses.q17.time[0]);
                }
            } catch (error) {
                console.error('Error fetching existing response:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchExistingResponse();
    }, [userId]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleNext = async () => {
        const startHour = value;
        const timeRange = `${formatHour(startHour)} - ${formatHour(startHour + 5)}`;
        const score = calculateScore(startHour);
        try {
            await saveResponse('q17', { time: timeRange, score: score });
            onNext();
        } catch (error) {
            console.error('Error saving response:', error);
        }
    };

    const formatHour = hour => `${Math.floor(hour % 24)}:00`;

    const calculateScore = (startHour) => {
        for (const { range, score } of scores) {
            if (startHour >= range[0] && startHour < range[1]) {
                return score;
            }
        }
        return 0;
    };

    const selectedScore = calculateScore(value);

    if (loading) {
        return <p>{t('questions.q17.loading')}</p>;
    }

    return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
            <h3>{t('questions.q17.title')}</h3>
            <div style={{ width: '80%', margin: '0 auto' }}>
                <Slider
                    min={0}
                    max={19}
                    step={1}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(x) => `${formatHour(x)} - ${formatHour(x + 5)}`}
                    track={false}
                    marks={[
                        { value: 0, label: '0:00' },
                        { value: 1, label: '1:00' },
                        { value: 2, label: '2:00' },
                        { value: 3, label: '3:00' },
                        { value: 4, label: '4:00' },
                        { value: 5, label: '5:00' },
                        { value: 6, label: '6:00' },
                        { value: 7, label: '7:00' },
                        { value: 8, label: '8:00' },
                        { value: 9, label: '9:00' },
                        { value: 10, label: '10:00' },
                        { value: 11, label: '11:00' },
                        { value: 12, label: '12:00' },
                        { value: 13, label: '13:00' },
                        { value: 14, label: '14:00' },
                        { value: 15, label: '15:00' },
                        { value: 16, label: '16:00' },
                        { value: 17, label: '17:00' },
                        { value: 18, label: '18:00' },
                        { value: 19, label: '19:00' },
                        { value: 20, label: '20:00' },
                        { value: 21, label: '21:00' },
                        { value: 22, label: '22:00' },
                        { value: 23, label: '23:00' },
                        { value: 24, label: '24:00' },
                    ]}
                    sx={{
                        '& .MuiSlider-thumb': {
                            backgroundColor: 'blue',
                        },
                        '& .MuiSlider-track': {
                            height: 10,
                            backgroundColor: 'blue',
                            border: 'none',
                        },
                        '& .MuiSlider-rail': {
                            height: 10,
                            backgroundColor: '#ccc',
                        },
                        '& .MuiSlider-markLabel': {
                            transform: 'translateX(-50%)',
                        }
                    }}
                />
            </div>
            <p>{t('questions.q17.sliderLabel')}: {formatHour(value)} - {formatHour(value + 5)}</p>
            <p>{t('questions.q17.scoreLabel')}: {selectedScore}</p>
            <button onClick={onPrevious} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}>{t('questions.q17.previous')}</button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button onClick={handleNext} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}>{t('questions.q17.next')}</button>
        </div>
    );
};

export default Q17;
