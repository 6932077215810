import axios from 'axios';
import { getToken } from './authService';

const API_URL = process.env.REACT_APP_API_URL + '/users/';
//const API_URL = 'http://localhost:5001/api/users/';

export const getUserProfile = async () => {
    const token = getToken();
    if (!token) {
        console.error('No token found');
        throw new Error('No token found. User must be logged in.');
    }

    const response = await axios.get(`${API_URL}me`, {
        headers: { Authorization: `Bearer ${token}` }
    });
    return response.data; // Extract the data property
};


export const updateUserProfile = async (data) => {
    const token = getToken();
    if (!token) {
        throw new Error('Authentication token is missing. Please log in.');
    }

    try {
        const response = await axios.post(`${API_URL}update`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data' // Set Content-Type for FormData
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to update user profile:', error);
        throw error;
    }
};

export const fetchAvatar = async (userId) => {
    try {
        const response = await axios.get(`${API_URL}${userId}/avatar`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            responseType: 'arraybuffer', // Binary data
        });
        const base64Image = btoa(
            new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        return base64Image;
    } catch (error) {
        console.error('Error getting user avatar:', error.message);
        throw new Error('Error getting user avatar', error.message);
    }
};

export const getAllUsers = async () => {
    const token = getToken();
    if (!token) {
        console.error('No token found');
        throw new Error('No token found. User must be logged in.');
    }

    try {
        const response = await axios.get(`${API_URL}all`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to retrieve users:', error);
        throw error;
    }
};

//  get user profile by ID
export const getUserProfileById = async (userId) => {
    const token = getToken();
    if (!token) {
        console.error('No token found');
        throw new Error('No token found. User must be logged in.');
    }

    try {
        console.log(`Fetching user profile by ID: ${userId}`);
        const response = await axios.get(`${API_URL}${userId}`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        console.log('User profile fetched:', response.data);
        return response.data;
    } catch (error) {
        console.error('Failed to retrieve user profile:', error);
        throw error;
    }
};

export const getUserAvatarUrl = (avatarPath) => {
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
    return avatarPath ? `${baseUrl}${avatarPath}` : null;
};

export const addNoteToUser = async (userId, comments) => {
    const token = getToken();
    if (!token) {
        throw new Error('Authentication token is missing. Please log in.');
    }

    try {
        const response = await axios.post(`${API_URL}${userId}/notes`, { comments }, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data; // Return the updated notes array
    } catch (error) {
        console.error('Failed to add note to user:', error);
        throw error;
    }
};

// Update a note for a user
export const updateNoteForUser = async (userId, noteId, comments) => {
    const token = getToken();
    if (!token) {
        throw new Error('Authentication token is missing. Please log in.');
    }

    try {
        const response = await axios.put(`${API_URL}${userId}/notes/${noteId}`, { comments }, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        return response.data;
    } catch (error) {
        console.error('Failed to update note for user:', error);
        throw error;
    }
};

// Delete a note for a user
export const deleteNoteForUser = async (userId, noteId) => {
    const token = getToken();
    if (!token) {
        throw new Error('Authentication token is missing. Please log in.');
    }

    try {
        const response = await axios.delete(`${API_URL}${userId}/notes/${noteId}`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data; // Return the updated notes array
    } catch (error) {
        console.error('Failed to delete note for user:', error);
        throw error;
    }
};

export const getAllNotes = async (userId) => {
    const token = getToken();
    if (!token) {
        throw new Error('Authentication token is missing. Please log in.');
    }

    try {
        const response = await axios.get(`${API_URL}${userId}/notes`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        console.log("reponse: ", response.data)
        return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
        console.error('Failed to fetch notes for user:', error);
        throw error;
    }
};

export const getNotificationAssignedAt = async (userId) => {
    const token = getToken();
    if (!token) {
        console.error('No token found');
        throw new Error('No token found. User must be logged in.');
    }

    try {
        const response = await axios.get(`${API_URL}${userId}/notificationsCreatedAt`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data; // Return the notificationAssignedAt array
    } catch (error) {
        console.error('Failed to retrieve notificationAssignedAt:', error);
        throw error;
    }
};