import React from 'react';
import './AboutMe.css';
import profilePicture from '../../../pic/avatar_me.png';



const AboutMe = () => {
    return (
        <div className='about-me-container'>
            <div className="about-me-section">
                <div className="about-me-content">
                    <div className="about-me-title">
                        <h2>About Us</h2>
                    </div>
                    <div className="about-me-description">
                        <p>We are a team of researchers from the University of Bamberg and the Deggendorf Institute of Technology. We conduct interdisciplinary research in the areas of occupational science, digitalization, and health psychology. With “You Flow”, we aim to explore time-sensitive work design and examine how daily planning based on circadian rhythms can be integrated into everyday work, as well as the effects this integration has. For this purpose, the “You Flow tool” was developed with Korero, a smart business solutions company.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutMe;