import React, { useState, useEffect } from 'react';
import defaultAvatar from '../../../../pic/avatar.png';
import { fetchAvatar } from '../../../../services/userService'; // No need to fetch profile if selectedUser is passed
import './PersonalInfo.css';
import CircularProgress from '@mui/material/CircularProgress';
import { handleSessionExpired } from '../../../SessionExpiredHandler';
import SessionExpiredModal from '../../../SessionExpiredModal';
import { useTranslation } from 'react-i18next';


const PersonalInfo = ({ user, onUpdate, editable = true }) => {
    const [editMode, setEditMode] = useState(false);
    const [avatar, setAvatar] = useState(defaultAvatar);
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        avatar: '',
        username: '',
        place: '',
        street: '',
        telephone: ''
    });
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    //   console.log("personal info selected user: ", user)

    useEffect(() => {
        const initializeData = async () => {
            try {
                if (user) {
                    // Use the passed user data directly
                    setFormData({
                        firstName: user.firstname || '',
                        lastName: user.lastname || '',
                        email: user.email || '',
                        avatar: user.avatar || '',
                        username: user.username || '',
                        place: user.place || '',
                        street: user.street || '',
                        telephone: user.telephone || ''
                    });

                    // Load avatar
                    await loadAvatar(user._id);
                }
            } catch (error) {
                console.error('Error initializing user data:', error);
            } finally {
                setLoading(false);
            }
        };

        initializeData();
    }, [user]);


    const loadAvatar = async (userId) => {
        try {
            const base64Image = await fetchAvatar(userId);
            setAvatar(`data:image/jpeg;base64,${base64Image}`);
        } catch (error) {
            console.error('Failed to load avatar:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            avatar: e.target.files[0],
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const updateFormData = new FormData();
        Object.keys(formData).forEach((key) => {
            updateFormData.append(key, formData[key]);
        });

        try {
            await onUpdate(updateFormData); // Pass FormData to onUpdate
            alert('Profile updated successfully!');
            setEditMode(false); // Exit edit mode after updating
        } catch (error) {
            console.error('Failed to update profile:', error);
            alert('Failed to update profile. Please check the console for more information.');
        }
    };

    if (loading) {
        return (
            <div className="loading-indicator">
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className="personal-info">
            {editMode && editable ? (
                <form onSubmit={handleSubmit} className="personal-info-form">
                    <label className="form-label">
                        {t('personalInfo.firstName')}:
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName || ''}
                            onChange={handleInputChange}
                            className="form-input"
                            disabled={!editable}
                        />
                    </label>
                    <label className="form-label">
                        {t('personalInfo.lastName')}:
                        <input
                            type="text"
                            name="lastName"
                            value={formData.lastName || ''}
                            onChange={handleInputChange}
                            className="form-input"
                            disabled={!editable}
                        />
                    </label>
                    <label className="form-label">
                        {t('personalInfo.place')}:
                        <input
                            type="text"
                            name="place"
                            value={formData.place || ''}
                            onChange={handleInputChange}
                            className="form-input"
                            disabled={!editable}
                        />
                    </label>
                    <label className="form-label">
                        {t('personalInfo.street')}:
                        <input
                            type="text"
                            name="street"
                            value={formData.street || ''}
                            onChange={handleInputChange}
                            className="form-input"
                            disabled={!editable}
                        />
                    </label>
                    <label className="form-label">
                        {t('personalInfo.telephone')}:
                        <input
                            type="text"
                            name="telephone"
                            value={formData.telephone || ''}
                            onChange={handleInputChange}
                            className="form-input"
                            disabled={!editable}
                        />
                    </label>
                    <label className="form-label">
                        {t('personalInfo.email')}:
                        <input
                            type="email"
                            name="email"
                            value={formData.email || ''}
                            onChange={handleInputChange}
                            className="form-input"
                            disabled={!editable}
                        />
                    </label>
                    <label className="form-label">
                        {t('personalInfo.avatar')}:
                        <input
                            type="file"
                            onChange={handleFileChange}
                            accept="image/*"
                            className="form-input"
                            disabled={!editable}
                        />
                    </label>
                    {editable && <button type="submit" className="submit-button">{t('personalInfo.save')}</button>}
                </form>
            ) : (
                <div className="personal-info-display">
                    <img src={avatar} alt="Avatar" className="avatar" />
                    <p className="info-text">
                        <strong>{t('personalInfo.username')}:</strong> {formData.username || t('personalInfo.notProvided')}
                    </p>
                    <p className="info-text">
                        <strong>{t('personalInfo.firstName')}:</strong> {formData.firstName || t('personalInfo.notProvided')}
                    </p>
                    <p className="info-text">
                        <strong>{t('personalInfo.lastName')}:</strong> {formData.lastName || t('personalInfo.notProvided')}
                    </p>
                    <p className="info-text">
                        <strong>{t('personalInfo.email')}:</strong> {formData.email || t('personalInfo.notProvided')}
                    </p>
                    <p className="info-text">
                        <strong>{t('personalInfo.place')}:</strong> {formData.place || t('personalInfo.notProvided')}
                    </p>
                    <p className="info-text">
                        <strong>{t('personalInfo.street')}:</strong> {formData.street || t('personalInfo.notProvided')}
                    </p>
                    <p className="info-text">
                        <strong>{t('personalInfo.telephone')}:</strong> {formData.telephone || t('personalInfo.notProvided')}
                    </p>
                    {editable && <button onClick={() => setEditMode(true)} className="edit-button">{t('personalInfo.edit')}</button>}
                </div>
            )}
            <SessionExpiredModal open={modalOpen} handleClose={() => setModalOpen(false)} />
        </div>
    );
};

export default PersonalInfo;