import React, { useEffect, useState } from 'react';
import { getDailyQuestionResponses } from '../../../../services/dailyQuestionService';
import DailySurveyChart from '../../Share/DailySurveyChart/DailySurveyChart';


const DailySurveyResult = ({ user }) => {
    const [responses, setResponses] = useState([]);

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await getDailyQuestionResponses(user._id);
                setResponses(response.data); // Store responses for the selected user
            } catch (error) {
                console.error("Error fetching daily survey responses:", error);
            }
        };

        fetchResponses();
    }, [user._id]);

    return (
        <div className="daily-survey-page">
            <h2>Daily Survey Data for User: {user.firstname}</h2>

            <DailySurveyChart responses={responses} />

        </div>
    );
};

export default DailySurveyResult;
