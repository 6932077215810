import React from 'react';
import uflowIcon from '../../../pic/uflowIcon.png';
import './Footer.css';

const Footer = () => {
    return (
        <div className='footer-container' >
            <div className="footer-section">
                <div className="footer-content">
                    <div className="footer-logo">
                        <img src={uflowIcon} alt="UFlow Logo" />

                    </div>
                    <div className="footer-links">
                        <div className="footer-column">
                            <h3>Company</h3>
                            <a href="#">About</a>
                            <a href="#">Testimonials</a>
                            <a href="#">Apps</a>
                        </div>

                        <div className="footer-column">
                            <h3>Help</h3>
                            <a href="#">Help center</a>
                            <a href="#">Contact support</a>
                            <a href="#">Instructions</a>
                            <a href="#">How it works</a>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>©UFLOW 2024. All rights reserved</p>
                </div>
            </div>
        </div>
    );
}

export default Footer;
