import React, { useEffect, useState } from 'react';
import { getPerformanceResponses } from '../../../../services/performanceService';
import { completeSurvey } from '../../../../services/surveyService';
import { getScheduledNotificationForObservationPeriod } from '../../../../services/notificationsService';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Alert } from '@mui/material';
import SurveyCalendar from './SurveyCalendar';
import { addDays, differenceInDays, isAfter, format, startOfDay, endOfDay, isSameDay, subHours } from 'date-fns';
import { useTranslation } from 'react-i18next';

const SurveyProgress = ({ userId }) => {
    const [daysCompleted, setDaysCompleted] = useState(0);
    const [totalSurveyDays, setTotalSurveyDays] = useState(0);
    const [loading, setLoading] = useState(true);
    const [surveyPeriods, setSurveyPeriods] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [questionsAnsweredToday, setQuestionsAnsweredToday] = useState(0);
    const [isInObservationPeriod, setIsInObservationPeriod] = useState(true);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [canRestartObservation, setCanRestartObservation] = useState(false);
    const [todayScheduledNotifications, setTodayScheduledNotifications] = useState(0);
    const { t } = useTranslation();

    const calculateProgress = async () => {
        try {
            const scheduledNotifications = await getScheduledNotificationForObservationPeriod(userId);

            if (!scheduledNotifications || !scheduledNotifications.dateRanges.length) {
                throw new Error('No scheduled notification date ranges found.');
            }

            const currentDate = new Date();
            let completedDays = 0;
            const uniqueDaysSet = new Set();

            // Iterate through each date range and collect unique dates for total survey days
            scheduledNotifications.dateRanges.forEach((range) => {
                range.notifications.forEach((notif) => {
                    const notifDate = format(new Date(notif.notificationTime), 'yyyy-MM-dd');
                    uniqueDaysSet.add(notifDate);
                });
            });

            const totalDays = uniqueDaysSet.size; // Count of all unique days in the notification set

            // Check if the current date is within any observation period
            const latestEndDate = scheduledNotifications.dateRanges.reduce((latest, range) =>
                isAfter(new Date(range.endDate), latest) ? new Date(range.endDate) : latest,
                new Date(scheduledNotifications.dateRanges[0].endDate)
            );

            if (isAfter(currentDate, latestEndDate)) {
                setIsInObservationPeriod(false); // Set to false if current date is beyond the latest end date
            } else {
                setIsInObservationPeriod(true); // Set to true if current date is within the observation period
            }

            // Logic for setting `todayScheduledNotifications`
            let todayScheduledNotificationsCount = 0;
            scheduledNotifications.dateRanges.forEach((range) => {
                const todayNotifications = range.notifications.filter((notif) =>
                    isSameDay(new Date(notif.notificationTime), currentDate)
                );

                // // For the first day of the range, include an extra notification check one hour before the first notification
                // if (isSameDay(currentDate, new Date(range.startDate)) && todayNotifications.length > 0) {
                //     const firstNotificationTime = new Date(todayNotifications[0].notificationTime);
                //     const extraNotificationTime = subHours(firstNotificationTime, 1);
                //     todayNotifications.unshift({ notificationTime: extraNotificationTime });
                // }

                todayScheduledNotificationsCount += todayNotifications.length;
            });

            setTodayScheduledNotifications(todayScheduledNotificationsCount);

            // Iterate through each date range to calculate completed days
            scheduledNotifications.dateRanges.forEach((range) => {
                const rangeStartDate = new Date(range.startDate);
                const rangeEndDate = new Date(range.endDate);
                const rangeUniqueDates = new Set();

                // Collect unique dates within the current date range
                range.notifications.forEach((notif) => {
                    const notifDate = format(new Date(notif.notificationTime), 'yyyy-MM-dd');
                    rangeUniqueDates.add(notifDate);
                });

                if (isAfter(currentDate, rangeEndDate)) {
                    // If the current date is after the end of this range, count all unique days in this range as completed
                    completedDays += rangeUniqueDates.size;
                } else if (currentDate >= rangeStartDate && currentDate <= rangeEndDate) {
                    // If the current date is within the current date range, count days from the start to the current day
                    rangeUniqueDates.forEach((date) => {
                        if (new Date(date) <= currentDate) {
                            completedDays++;
                        }
                    });
                }
            });

            setDaysCompleted(completedDays);
            setTotalSurveyDays(totalDays);
            setSurveyPeriods(scheduledNotifications.dateRanges);
            setLoading(false);

            // Log the unique days set for verification
            console.log('Total unique days:', totalDays);
            console.log('Completed days:', completedDays);
            console.log('Today scheduled notifications:', todayScheduledNotificationsCount);

            // Check if enough responses have been recorded
            await checkResponsesCompletion(scheduledNotifications);
        } catch (error) {
            console.error('Error calculating survey progress:', error);
            setLoading(false);
        }
    };


    const fetchTodayPerformance = async () => {
        try {
            const scheduledNotifications = await getScheduledNotificationForObservationPeriod(userId);

            if (!scheduledNotifications || !scheduledNotifications.dateRanges.length) {
                throw new Error('No scheduled notification date ranges found.');
            }

            // Get the latest date range and filter notifications for today
            const latestDateRange = scheduledNotifications.dateRanges.reduce((latest, current) =>
                isAfter(new Date(current.endDate), new Date(latest.endDate)) ? current : latest
            );

            const currentDate = new Date();
            const todayFormatted = format(currentDate, 'yyyy-MM-dd');

            // Filter notifications for today
            const todayNotifications = latestDateRange.notifications.filter((notif) =>
                isSameDay(new Date(notif.notificationTime), currentDate)
            );

            // // For the first day, include an extra hour check
            // if (isSameDay(currentDate, new Date(latestDateRange.startDate)) && todayNotifications.length > 0) {
            //     const firstNotificationTime = new Date(todayNotifications[0].notificationTime);
            //     const extraNotificationTime = subHours(firstNotificationTime, 1);
            //     todayNotifications.unshift({ notificationTime: extraNotificationTime });
            // }

            // Get performance responses and filter based on notification times
            const response = await getPerformanceResponses(userId);
            const todayResponses = response.data.filter((entry) => {
                const entryDate = new Date(entry.timestamp);

                // Check if the entry matches any notification time (consider date and hour only)
                return todayNotifications.some((notif) => {
                    const notifDate = new Date(notif.notificationTime);
                    return format(entryDate, 'yyyy-MM-dd HH') === format(notifDate, 'yyyy-MM-dd HH');
                });
            });
            console.log(todayResponses)

            setQuestionsAnsweredToday(todayResponses.length);
        } catch (error) {
            console.error("Error fetching today's performance responses:", error);
        }
    };


    const checkResponsesCompletion = async (notificationData) => {
        try {
            // Ensure notificationData has the expected structure
            if (!notificationData || !notificationData.dateRanges || notificationData.dateRanges.length === 0) {
                console.error('No date ranges found in the notification data.');
                setCanRestartObservation(false);
                return;
            }

            const performanceData = await getPerformanceResponses(userId);

            // Flatten all notifications within the date ranges into a single array
            const allScheduledNotifications = notificationData.dateRanges.flatMap((dateRange) => {
                if (dateRange && dateRange.notifications && Array.isArray(dateRange.notifications)) {
                    return dateRange.notifications.map((notif) => new Date(notif.notificationTime));
                } else {
                    return []; // Handle cases where notifications might be undefined or not an array
                }
            });

            if (allScheduledNotifications.length === 0) {
                console.error('No scheduled notifications found in the date ranges.');
                setCanRestartObservation(false);
                return;
            }

            // Filter performance data to include only responses with timestamps that match notification times
            const responsesMatchingNotifications = allScheduledNotifications.every((notifTime) => {
                return performanceData.data.some((entry) => {
                    const entryDate = new Date(entry.timestamp);
                    return format(entryDate, 'yyyy-MM-dd HH') === format(notifTime, 'yyyy-MM-dd HH');
                });
            });


            if (responsesMatchingNotifications) {
                setCanRestartObservation(true);
            } else {
                setCanRestartObservation(false);
            }
            console.log("can restart", canRestartObservation)

        } catch (error) {
            console.error('Error checking response completion:', error);
        }
    };


    useEffect(() => {
        calculateProgress();
        fetchTodayPerformance();
    }, [userId]);

    const handleRestartSurvey = async () => {
        setLoading(true);
        setConfirmDialogOpen(false);

        try {
            await completeSurvey(userId);

            setDaysCompleted(0);
            setTotalSurveyDays(0);
            setSurveyPeriods([]);
            setQuestionsAnsweredToday(0);
            setIsInObservationPeriod(true);

            setTimeout(async () => {
                await calculateProgress();
                await fetchTodayPerformance();
                setLoading(false);
            }, 1000);
        } catch (error) {
            console.error('Error restarting survey:', error);
            setLoading(false);
            setErrorSnackbarOpen(true);
        }
    };

    const handleOpenConfirmDialog = () => {
        setConfirmDialogOpen(true);
    };

    const handleCloseConfirmDialog = () => {
        setConfirmDialogOpen(false);
    };

    const handleCloseErrorSnackbar = () => {
        setErrorSnackbarOpen(false);
    };

    if (loading) {
        return <Typography>{t('surveyProgress.loading')}</Typography>;
    }

    const percentage = (daysCompleted / totalSurveyDays) * 100;
    const todayPercentage = (questionsAnsweredToday / todayScheduledNotifications) * 100; // Use scheduled notifications as duration

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, padding: 4 }}>
            {showCalendar ? (
                <SurveyCalendar surveyPeriods={surveyPeriods} onReturn={() => setShowCalendar(false)} />
            ) : (
                <>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Box sx={{ width: 100, height: 100 }}>
                            <CircularProgressbar
                                value={percentage}
                                text={`${daysCompleted}/${totalSurveyDays}`}
                                styles={buildStyles({
                                    textSize: '16px',
                                    textColor: '#3e98c7',
                                    pathColor: daysCompleted === totalSurveyDays ? '#4caf50' : '#3e98c7',
                                    trailColor: '#d6d6d6',
                                })}
                            />
                        </Box>

                        <Box sx={{ width: 100, height: 100 }}>
                            <CircularProgressbar
                                value={todayPercentage}
                                text={`${questionsAnsweredToday}/${todayScheduledNotifications}`} // Display with scheduled notifications
                                styles={buildStyles({
                                    textSize: '16px',
                                    textColor: '#3e98c7',
                                    pathColor: questionsAnsweredToday >= todayScheduledNotifications ? '#4caf50' : '#3e98c7',
                                    trailColor: '#d6d6d6',
                                })}
                            />
                        </Box>
                    </Box>
                    <Typography variant="h6">
                        {t('surveyProgress.daysCompleted', { daysCompleted, totalSurveyDays })}
                    </Typography>
                    <Typography variant="body1">
                        {daysCompleted === totalSurveyDays
                            ? t('surveyProgress.congratulations')
                            : t('surveyProgress.daysLeft', { daysLeft: totalSurveyDays - daysCompleted })}
                    </Typography>

                    {!isInObservationPeriod && !canRestartObservation && (
                        <Typography variant="body2" color="error" sx={{ mt: 2, textAlign: 'center' }}>
                            {t('surveyProgress.incompleteNotice')}
                        </Typography>
                    )}

                    {!isInObservationPeriod && canRestartObservation && !loading && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenConfirmDialog}
                            disabled={loading}
                            sx={{ mt: 2 }}
                        >
                            {t('surveyProgress.restartSurvey')}
                        </Button>
                    )}

                    <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
                        <DialogTitle>{t('surveyProgress.confirmRestartTitle')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {t('surveyProgress.confirmRestartText')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseConfirmDialog}>{t('surveyProgress.cancel')}</Button>
                            <Button onClick={handleRestartSurvey} color="primary">
                                {t('surveyProgress.confirm')}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Snackbar
                        open={errorSnackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleCloseErrorSnackbar}
                    >
                        <Alert onClose={handleCloseErrorSnackbar} severity="error" sx={{ width: '100%' }}>
                            {t('surveyProgress.restartError')}
                        </Alert>
                    </Snackbar>
                </>
            )}
        </Box>
    );
};

export default SurveyProgress;
