import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const Q20 = ({ userId, onNext, onPrevious, saveResponse }) => {
    const [startTime, setStartTime] = useState('');
    const [duration, setDuration] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchExistingResponse = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                if (!user || !user.token) {
                    throw new Error('No token found in localStorage');
                }
                const token = user.token;

                const baseUrl = process.env.REACT_APP_API_URL;

                const response = await axios.get(`${baseUrl}/survey/get-response/q20/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (response.data && response.data.score) {
                    setSelectedOption(response.data);
                }
            } catch (error) {
                console.error('Error fetching existing response:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchExistingResponse();
    }, [userId]);


    // Generate options for start times (e.g., 6:00 AM to 6:00 PM in 1-hour intervals)
    const startTimeOptions = Array.from({ length: 7 }, (_, i) => ({
        value: `${i + 6}:00`,
        label: `${i + 6}:00`,
    }));

    // Duration options (between 6 and 10 hours)
    const durationOptions = Array.from({ length: 5 }, (_, i) => ({
        value: i + 6,
        label: `${i + 6} ${t('questions.q20.hours')}`
    }));

    const handleNext = () => {
        saveResponse('q20', { startTime, duration });
        onNext();
    };

    return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
            <h3>{t('questions.q20.title')}</h3>
            <p style={{ marginBottom: '20px', fontSize: '14px', color: '#555' }}>
                {t('questions.q20.instruction')}
            </p>
            <form>
                <div>
                    <label>{t('questions.q20.startTime')}</label>
                    <select
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                        style={{ margin: '10px', padding: '8px', fontSize: '16px' }}
                    >
                        <option value="">{t('questions.q20.selectStartTime')}</option>
                        {startTimeOptions.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>{t('questions.q20.duration')}</label>
                    <select
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                        style={{ margin: '10px', padding: '8px', fontSize: '16px' }}
                    >
                        <option value="">{t('questions.q20.selectDuration')}</option>
                        {durationOptions.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
            </form>
            <button onClick={onPrevious} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}>
                {t('questions.q20.previous')}
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button onClick={handleNext} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }} disabled={!startTime || !duration}>
                {t('questions.q20.next')}
            </button>
        </div>
    );

};

export default Q20;
