import React, { useEffect, useState } from 'react';
import { getAllUsers } from '../../../../services/userService';
import { getPerformanceResponses } from '../../../../services/performanceService';
import { getDailyQuestionResponses } from '../../../../services/dailyQuestionService';
import './UserList.css';

const UserList = ({ onUserClick, onOverviewClick, onNoteClick, user }) => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 10;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getAllUsers();
                const usersWithActivity = await Promise.all(res.map(async (restUser) => {
                    if (restUser._id !== user._id) {
                        let lastPerformanceDate = null;
                        let lastDailyQuestionDate = null;
                        let lastActive = "Initial survey not completed yet or no daily/hourly records";

                        try {
                            const performanceData = await getPerformanceResponses(restUser._id);
                            if (performanceData && performanceData.data && performanceData.data.length > 0) {
                                lastPerformanceDate = new Date(
                                    Math.max(
                                        ...performanceData.data.map(r => new Date(r.timestamp).getTime())
                                    )
                                );
                            }
                        } catch (error) {
                            if (error.response && error.response.status === 404) {
                                console.warn(`No performance data for user ${restUser._id}`);
                            } else {
                                throw error; // Re-throw other errors
                            }
                        }

                        try {
                            const dailyQuestionData = await getDailyQuestionResponses(restUser._id);
                            if (dailyQuestionData?.data.length > 0) {
                                lastDailyQuestionDate = new Date(
                                    Math.max(
                                        ...dailyQuestionData.data.map(r => new Date(r.date).getTime())
                                    )
                                );
                            }
                        } catch (error) {
                            if (error.response && error.response.status === 404) {
                                console.warn(`No daily question data for user ${restUser._id}`);
                            } else {
                                throw error; // Re-throw other errors
                            }
                        }

                        // Determine the most recent date
                        const lastActiveDate = [lastPerformanceDate, lastDailyQuestionDate].filter(Boolean).sort((a, b) => b - a)[0];
                        if (lastActiveDate) {
                            lastActive = lastActiveDate;
                        }

                        return { ...restUser, lastActive };
                    }
                    return null;
                }));

                const filteredUsers = usersWithActivity.filter(Boolean); // Remove null values (logged-in user)
                setUsers(filteredUsers);
                setFilteredUsers(filteredUsers);
            } catch (error) {
                console.error("Error fetching users or activity data:", error);
                setUsers([]);
                setFilteredUsers([]);
            }
        };

        fetchData();
    }, [user._id]);

    useEffect(() => {
        const results = users.filter(
            u =>
                u.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                u.lastname.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredUsers(results);
        setCurrentPage(1);  // Reset to the first page after search
    }, [searchTerm, users]);

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(filteredUsers.length / usersPerPage)));
    };

    const handlePreviousPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    return (
        <div className="user-list-container">
            <h2>Participants List ({filteredUsers.length})</h2>
            <input
                type="text"
                placeholder="Search by first name or last name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-bar"
            />
            <table>
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Date Added</th>
                        <th>Last Active</th>
                        <th>Survey Details</th>
                        <th>Overview</th> {/* Replace Performance and Daily Survey with Overview */}
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {currentUsers.map(user => (
                        <tr key={user._id}>
                            <td>{user.firstname}</td>
                            <td>{user.lastname}</td>
                            <td>{user.email}</td>
                            <td>{new Date(user.createdAt).toLocaleDateString()}</td>
                            <td>
                                {typeof user.lastActive === 'string'
                                    ? user.lastActive
                                    : new Date(user.lastActive).toLocaleDateString()}
                            </td>
                            <td>
                                <button onClick={() => onUserClick(user._id)}>View Survey</button>
                            </td>
                            <td>
                                <button onClick={() => onOverviewClick(user._id)}>Statistics</button> {/* New Overview button */}
                            </td>
                            <td>
                                <button onClick={() => onNoteClick(user._id)}>Add Notes</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination">
                <button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</button>
                <span>Page {currentPage} of {Math.ceil(filteredUsers.length / usersPerPage)}</span>
                <button onClick={handleNextPage} disabled={currentPage === Math.ceil(filteredUsers.length / usersPerPage)}>Next</button>
            </div>
        </div>
    );
};

export default UserList;
