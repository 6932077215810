import React, { createContext, useContext, useEffect, useCallback, useRef, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { v4 as uuidv4 } from 'uuid';
import soundEffect from '../../soundEffect.mp3';

const NotificationContext = createContext();

const getBaseURL = () => {
    if (window.location.hostname === 'localhost') {
        return 'http://localhost:5001';
    } else {
        return window.location.origin;
    }
};

export const NotificationProvider = ({ children, user, setContent, content, navigate }) => {
    const userRef = useRef(user);
    const eventSourceRef = useRef(null);
    const [addedNotificationIds, setAddedNotificationIds] = useState(new Set());
    const [isDailySurveyDone, setIsDailySurveyDone] = useState(false);
    const notificationSound = new Audio(soundEffect);
    const location = useLocation();

    // Check if Notification API is supported
    useEffect(() => {
        if ('Notification' in window) {
            if (Notification.permission !== 'granted') {
                Notification.requestPermission();
            }
        } else {
            console.log('Notification API is not supported in this browser.');
        }
    }, []);

    // Handle answering the question when a notification is clicked
    const handleAnswerQuestion = useCallback((notification) => {
        console.log('Default question answered handler:', notification);
        if (location.pathname !== '/dashboard' && notification.type !== 'daily') {
            navigate('/dashboard', { replace: true });
            setTimeout(() => {
                if (notification.type === 'hourly') {
                    setContent('performanceQuestion');
                }
            }, 100);
        } else {
            if (notification.type === 'hourly') {
                setContent('performanceQuestion');
            } else if (notification.type === 'daily') {
                setContent('DailySurvey');
            }
        }
    }, [setContent, navigate]);

    // Show a notification and play a sound
    const showNotificationWithSound = (title, message, notificationData) => {
        NotificationManager.info(
            message,
            title,
            5000, // Auto close after 5 seconds
            () => handleAnswerQuestion(notificationData)
        );

        // Only play sound if user interaction happened
        notificationSound.play().catch(error => {
            console.error('Error playing notification sound:', error);
        });
    };

    // Add notification to the UI
    const addNotification = useCallback((notification) => {
        const { title, message, type, _id } = notification;

        if (!_id) {
            console.error('Notification _id is missing', notification);
            return;
        }

        const notificationKey = `${_id}-${type}`;
        if (addedNotificationIds.has(notificationKey)) {
            console.log('Duplicate notification detected:', notificationKey);
            return;
        }

        showNotificationWithSound(title, message, notification);

        setAddedNotificationIds(prevIds => {
            const newSet = new Set(prevIds);
            newSet.add(notificationKey);
            return newSet;
        });
    }, [handleAnswerQuestion, addedNotificationIds]);

    // Trigger daily survey notification if not done
    const checkAndAddNotification = useCallback(() => {
        if (!isDailySurveyDone) {
            const notification = {
                title: 'Daily Survey Reminder',
                message: 'Please fill out your daily survey.',
                type: 'daily',
                _id: uuidv4(),
            };

            console.log('Adding notification:', notification);
            showNotificationWithSound(notification.title, notification.message, notification);
        }
    }, [isDailySurveyDone, handleAnswerQuestion]);

    const markDailySurveyAsDone = useCallback(() => {
        setIsDailySurveyDone(true);
        NotificationManager.listNotify = [];
    }, []);

    useEffect(() => {
        userRef.current = user;
    }, [user]);

    // Set up Server-Sent Events for real-time notifications
    useEffect(() => {
        if (!user) return;

        if (eventSourceRef.current) {
            eventSourceRef.current.close();
            console.log('Closed existing EventSource connection');
        }

        const connect = () => {
            const baseURL = getBaseURL();
            console.log('Connecting to EventSource at', `${baseURL}/events`);
            eventSourceRef.current = new EventSource(`${baseURL}/events`);

            eventSourceRef.current.onopen = () => {
                console.log('Connection to server opened.');
            };

            eventSourceRef.current.onmessage = (event) => {
                console.log('Received event:', event);
                const notification = JSON.parse(event.data);
                console.log('Parsed notification:', notification);
                const userId = userRef.current?._id;
                if (userId && notification.userId === userId) {
                    console.log('Adding notification for user:', userId);
                    addNotification(notification);
                } else {
                    console.log('Notification not for this user:', userId);
                }
            };

            eventSourceRef.current.onerror = (error) => {
                console.error('EventSource failed. Reconnecting...', error);
                if (eventSourceRef.current.readyState === EventSource.CLOSED) {
                    console.log('EventSource closed, attempting to reconnect...');
                    setTimeout(connect, 3000); // Retry connection after 3 seconds
                }
            };
        };

        connect();

        return () => {
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
                console.log('Cleanup: Closed EventSource connection');
            }
        };
    }, [user, addNotification]);

    return (
        <NotificationContext.Provider value={{ user, addNotification, checkAndAddNotification, markDailySurveyAsDone }}>
            {children}
            <NotificationContainer />
        </NotificationContext.Provider>
    );
};

export const useNotifications = () => useContext(NotificationContext);

export default NotificationContext;