import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { getDailyQuestionResponses } from '../../../../services/dailyQuestionService';
import { getPerformanceResponses } from '../../../../services/performanceService';
import { useTranslation } from 'react-i18next';

const ToDoList = ({ userId, setContent, isAdmin }) => {
    const [dailySurveyDone, setDailySurveyDone] = useState(false);
    const [performanceQuestionDone, setPerformanceQuestionDone] = useState(false);
    const [currentHour, setCurrentHour] = useState(new Date().getHours());
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const checkTasks = async () => {
            try {
                const dailyResponse = await getDailyQuestionResponses(userId);
                const performanceResponse = await getPerformanceResponses(userId);

                const dailyResponses = dailyResponse.data;
                const performanceResponses = performanceResponse.data;

                const today = new Date().toISOString().split('T')[0];
                const currentHour = new Date().getHours();
                setCurrentHour(currentHour);

                const hasDailySurveyToday = Array.isArray(dailyResponses) && dailyResponses.some(response =>
                    response.date.startsWith(today)
                );

                setDailySurveyDone(hasDailySurveyToday);

                const hasPerformanceThisHour = Array.isArray(performanceResponses) && performanceResponses.some(response => {
                    const responseHour = new Date(response.timestamp).getHours();
                    return new Date(response.timestamp).toISOString().split('T')[0] === today && responseHour === currentHour;
                });

                setPerformanceQuestionDone(hasPerformanceThisHour);

                setLoading(false);
            } catch (error) {
                console.error('Error checking tasks:', error);
                setLoading(false);
            }
        };

        checkTasks();
    }, [userId]);

    if (loading) {
        return <Typography>{t('toDoList.loading')}</Typography>;
    }

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h6" gutterBottom>
                {t('toDoList.todaysTasks')}
            </Typography>
            <List>
                <ListItem
                    button={!isAdmin}
                    onClick={() => !isAdmin && setContent('DailySurvey')}
                    disabled={isAdmin}
                >
                    <ListItemIcon>
                        {dailySurveyDone ? <CheckCircleIcon color="primary" /> : <RadioButtonUncheckedIcon color="error" />}
                    </ListItemIcon>
                    <ListItemText
                        primary={t('toDoList.completeDailySurvey')}
                        secondary={dailySurveyDone ? t('toDoList.completed') : t('toDoList.toDo')}
                    />
                </ListItem>
                <ListItem
                    button={!isAdmin}
                    onClick={() => !isAdmin && setContent('performanceQuestion')}
                    disabled={isAdmin}
                >
                    <ListItemIcon>
                        {performanceQuestionDone ? <CheckCircleIcon color="primary" /> : <RadioButtonUncheckedIcon color="error" />}
                    </ListItemIcon>
                    <ListItemText
                        primary={performanceQuestionDone
                            ? t('toDoList.completePerformanceQuestion')
                            : t('toDoList.completePerformanceQuestionAt', { currentHour })}
                        secondary={performanceQuestionDone ? t('toDoList.completed') : t('toDoList.toDo')}
                    />
                </ListItem>
            </List>
        </Box>
    );
};

export default ToDoList;
