import React, { useState, useEffect } from 'react';
import { savePerformanceResponse, getPerformanceResponses } from '../../../../../services/performanceService';
import { startOfWeek, endOfWeek } from 'date-fns';
import PerformanceChart from '../../../Share/PerformanceChart';
import './PerformanceQuestion.css';
import { useTranslation } from 'react-i18next';

const PerformanceQuestion = ({ userId, selectedTimestamp, onClose }) => {
    const [score, setScore] = useState(5);
    const [responses, setResponses] = useState([]);
    const [tab, setTab] = useState('question');
    const [currentResponse, setCurrentResponse] = useState(null);
    const [currentWeek, setCurrentWeek] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));
    const { t } = useTranslation();

    useEffect(() => {
        fetchResponses();
    }, [currentWeek, selectedTimestamp]);

    const fetchResponses = async () => {
        try {
            const res = await getPerformanceResponses(userId);

            // Filter responses for the current week
            const weekStart = startOfWeek(currentWeek, { weekStartsOn: 1 });
            const weekEnd = endOfWeek(currentWeek, { weekStartsOn: 1 });
            const filteredResponses = res.data.filter((response) => {
                const responseTime = new Date(response.timestamp);
                return responseTime >= weekStart && responseTime <= weekEnd;
            });

            setResponses(filteredResponses);

            // Check for response matching the selected timestamp (or current time)
            const selectedTime = new Date(selectedTimestamp || new Date());
            const startOfHour = new Date(
                selectedTime.getFullYear(),
                selectedTime.getMonth(),
                selectedTime.getDate(),
                selectedTime.getHours()
            );
            const endOfHour = new Date(startOfHour.getTime() + 60 * 60 * 1000);

            const responseForSelectedTime = filteredResponses.find((response) => {
                const responseTime = new Date(response.timestamp);
                return responseTime >= startOfHour && responseTime < endOfHour;
            });

            setCurrentResponse(responseForSelectedTime);
        } catch (error) {
            console.error('Error fetching performance responses:', error);
        }
    };

    const handleSaveResponse = async () => {
        try {
            const timestamp = selectedTimestamp || new Date().toISOString();
            await savePerformanceResponse(userId, { score, timestamp });
            alert(t('performanceQuestion.responseSaved'));
            fetchResponses(); // Refresh responses after saving
            if (onClose) onClose(); // Close the modal if callback provided
        } catch (error) {
            console.error('Error saving performance response:', error);
            alert(t('performanceQuestion.responseSaveError'));
        }
    };

    const renderQuestionTab = () => (
        <div className="tab-content">
            <h3>{t('performanceQuestion.questionTitle')}</h3>
            {currentResponse ? (
                <div>
                    <p>
                        {t('performanceQuestion.alreadySubmitted', {
                            time: new Date(currentResponse.timestamp).toLocaleTimeString(),
                        })}
                    </p>
                    <p>
                        {t('performanceQuestion.score')}: {currentResponse.score}
                    </p>
                </div>
            ) : (
                <div>
                    <input
                        type="range"
                        min="0"
                        max="10"
                        value={score}
                        onChange={(e) => setScore(e.target.value)}
                        className="slider"
                    />
                    <p>
                        {t('performanceQuestion.score')}: {score}
                    </p>
                    <button onClick={handleSaveResponse} className="save-button">
                        {t('dailySurvey.save')}
                    </button>
                </div>
            )}
        </div>
    );

    const renderHistoryTab = () => (
        <div className="tab-content">
            <h3>{t('performanceQuestion.responseHistory')}</h3>
            <ul className="response-history">
                {responses.map((response, index) => (
                    <li key={index}>
                        {new Date(response.timestamp).toLocaleString()}: {response.score}
                    </li>
                ))}
            </ul>
        </div>
    );

    return (
        <div className="performance-question">
            <div className="tabs">
                <button
                    onClick={() => setTab('question')}
                    className={tab === 'question' ? 'active' : ''}
                >
                    {t('performanceQuestion.questionTab')}
                </button>
                <button
                    onClick={() => setTab('history')}
                    className={tab === 'history' ? 'active' : ''}
                >
                    {t('performanceQuestion.historyTab')}
                </button>
                <button
                    onClick={() => setTab('chart')}
                    className={tab === 'chart' ? 'active' : ''}
                >
                    {t('performanceQuestion.chartTab')}
                </button>
            </div>
            <div className="tab-container">
                {tab === 'question' && renderQuestionTab()}
                {tab === 'history' && renderHistoryTab()}
                {tab === 'chart' && (
                    <PerformanceChart
                        responses={responses}
                        currentWeek={currentWeek}
                        setCurrentWeek={setCurrentWeek}
                    />
                )}
            </div>
        </div>
    );
};

export default PerformanceQuestion;
