import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserProfile, updateUserProfile, getUserProfileById } from '../../../services/userService';
import PersonalInfo from '../Share/PersonalInfo/PersonalInfo';
import UserList from './UserList/UserList';
import UserDetail from './UserDetail';
import SetNotification from '../../Notification/SetNotification/SetNotification';
import UserPerformance from './UserPerformance';
import DailySurvey from './DailySurveyResult/DailySurveyResult';
import NoteManager from './NoteManager/NoteManager';
import { NotificationProvider } from '../../Notification/NotificationContext';
import NavBar from '../NavBar';
import { UserContext } from '../../../contexts/UserContext';
import { getDailyQuestionResponses } from '../../../services/dailyQuestionService';
import { getPerformanceResponses } from '../../../services/performanceService';
import Overview from '../Share/Overview/Overview';

const AdminDashboard = ({ content, setContent }) => {
    const { user, setUser } = useContext(UserContext);
    const [selectedUser, setSelectedUser] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [desiredContent, setDesiredContent] = useState('userDetail');
    const [dailySurveyResponses, setDailySurveyResponses] = useState([]);
    const [performanceResponses, setPerformanceResponses] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedUser = JSON.parse(localStorage.getItem('user'));
                const token = storedUser?.token;

                if (!token) {
                    throw new Error('No token found in localStorage');
                }

                const userProfile = await getUserProfile(token);

                // Save the updated user profile to localStorage
                const completeUser = { ...userProfile, token };
                localStorage.setItem('user', JSON.stringify(completeUser));

                setUser(userProfile);
            } catch (error) {
                console.error("Error fetching user profile or checking survey completion:", error);
            }
        };

        fetchData();
    }, [setUser, setContent]);

    const handleUpdateUserInfo = async (updatedData) => {
        try {
            const updatedUser = await updateUserProfile(updatedData);
            const storedUser = JSON.parse(localStorage.getItem('user'));
            const completeUser = { ...updatedUser, token: storedUser.token };
            setUser(completeUser);
            localStorage.setItem('user', JSON.stringify(completeUser));
            window.location.reload();
        } catch (error) {
            console.error("Error updating user info:", error);
            alert('Failed to update user info. Please check the console for more information.');
        }
    };

    const handlePerformanceClick = async (userId) => {
        setDesiredContent('userPerformance');
        try {
            const userData = await getUserProfileById(userId);
            if (userData) {
                setSelectedUser(userData);
            } else {
                console.error('User data is undefined');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const handleUserClick = async (userId) => {
        setDesiredContent('userDetail');
        try {
            const userData = await getUserProfileById(userId);
            if (userData) {
                setSelectedUser(userData);
            } else {
                console.error('User data is undefined');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const handleDailySurveyClick = async (userId) => {
        setDesiredContent('dailySurvey');
        try {
            const userData = await getUserProfileById(userId);
            if (userData) {
                setSelectedUser(userData);
            } else {
                console.error('User data is undefined');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const handleOverviewClick = async (userId) => {  // New function to handle Overview click
        setDesiredContent('overview');
        try {
            const userData = await getUserProfileById(userId);
            if (userData) {
                setSelectedUser(userData);

                // Fetch daily survey responses
                const dailyResponse = await getDailyQuestionResponses(userId);
                setDailySurveyResponses(dailyResponse.data || []);

                // Fetch performance responses
                const performanceResponse = await getPerformanceResponses(userId);
                setPerformanceResponses(performanceResponse.data || []);

            } else {
                console.error('User data is undefined');
                setDailySurveyResponses([]);
                setPerformanceResponses([]);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            setDailySurveyResponses([]);
            setPerformanceResponses([]);
        }
    };

    const handleNoteClick = async (userId) => {
        setDesiredContent('noteManager');
        try {
            const userData = await getUserProfileById(userId);
            if (userData) {
                setSelectedUser(userData);
            } else {
                console.error('User data is undefined');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    useEffect(() => {
        if (selectedUser) {
            setContent(desiredContent);
        }
    }, [selectedUser, desiredContent, setContent]);

    const handleSetNotificationClick = (userId) => {
        setSelectedUser({ _id: userId });
        setContent('setNotification');
    };

    const logout = () => {
        localStorage.clear();
        navigate('/login'); // Redirect to login page
    };

    return (
        <NotificationProvider>
            <div style={{ display: 'flex', minHeight: '100vh' }}>
                <NavBar
                    user={user}
                    setDrawerOpen={setDrawerOpen}
                    logout={logout}
                    drawerOpen={drawerOpen}
                    handleMenuItemClick={setContent}
                />
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <main style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ textAlign: 'center', width: '100%' }}>
                            {content === 'personalInfo' && user && (
                                <>
                                    <PersonalInfo
                                        user={user}
                                        onUpdate={handleUpdateUserInfo}
                                        editable={true}
                                    />
                                </>
                            )}
                            {content === 'userDetail' && selectedUser && (
                                <>
                                    <UserDetail
                                        user={selectedUser}
                                        editable={false} // The logged-in user cannot edit another user's profile
                                    />
                                </>
                            )}

                            {content === 'userList' && (
                                <UserList
                                    onUserClick={handleUserClick}
                                    onSetNotificationClick={handleSetNotificationClick}
                                    onPerformanceClick={handlePerformanceClick}
                                    onDailySurveyClick={handleDailySurveyClick}
                                    onOverviewClick={handleOverviewClick}
                                    onNoteClick={handleNoteClick}
                                    user={user}
                                />
                            )}
                            {content === 'setNotification' && <SetNotification userId={selectedUser?._id} onBack={() => setContent('userList')} />}
                            {content === 'userPerformance' && selectedUser && <UserPerformance user={selectedUser} />}
                            {content === 'dailySurvey' && selectedUser && <DailySurvey user={selectedUser} />}
                            {content === 'noteManager' && selectedUser && <NoteManager user={selectedUser} />}
                            {content === 'overview' && selectedUser && (
                                <Overview
                                    userId={selectedUser._id}
                                    setContent={setContent}
                                    dailySurveyResponses={dailySurveyResponses}
                                    performanceResponses={performanceResponses}
                                    isAdmin={user.isAdmin}
                                />
                            )}
                        </div>
                    </main>
                </div>
            </div>
        </NotificationProvider>
    );
};

export default AdminDashboard;
