import React from 'react';
import { Modal, Button, Typography } from '@mui/material';
import { handleCloseModal } from '../components/SessionExpiredHandler';

const SessionExpiredModal = ({ open, handleClose }) => {
    const handleLoginClick = () => {
        handleCloseModal(); // Redirect to login
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="session-expired-title"
            aria-describedby="session-expired-description"
        >
            <div style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', position: 'absolute', width: 400, backgroundColor: 'white', padding: 20, boxShadow: 24 }}>
                <Typography id="session-expired-title" variant="h6" component="h2">
                    Session Expired
                </Typography>
                <Typography id="session-expired-description" sx={{ mt: 2 }}>
                    Your session has expired. Please log in again.
                </Typography>
                <Button variant="contained" color="primary" onClick={handleLoginClick} style={{ marginTop: 20 }}>
                    Login
                </Button>
            </div>
        </Modal>
    );
};

export default SessionExpiredModal;
