import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + '/daily-question';

export const saveDailyQuestionResponse = async (userId, data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user?.token;
    if (!token) throw new Error('No token found');

    const response = await axios.post(`${API_URL}/saveOrUpdateDailyResponse`, data, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
};


export const getDailyQuestionResponses = async (userId) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user?.token;
    if (!token) throw new Error('No token found');

    try {
        const response = await axios.get(`${API_URL}/${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            console.warn('No daily question responses found for this user.');
            return { data: [] }; // Return an empty array or suitable empty response
        } else {
            console.error('Error fetching daily question responses:', error);
            throw error; // Re-throw other errors
        }
    }
};


export const updateDailyQuestionResponse = async (id, data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user?.token;
    if (!token) throw new Error('No token found');

    try {
        const response = await axios.put(`${API_URL}/response/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        console.error('Error updating daily question response:', error);
        throw error;
    }
};