import React, { useEffect, useState, createContext, useContext } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
    auth: {
        clientId: '9808a8f0-5a17-4b85-b144-8bcb9687bf4d',
        authority: `https://login.microsoftonline.com/bc9f0276-11b7-410b-bfec-f0d7fba3336c`,
        redirectUri: window.location.origin
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    }
};

const pca = new PublicClientApplication(msalConfig);

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        const initializeMsal = async () => {
            try {
                await pca.initialize();
                setInitialized(true);
            } catch (error) {
                console.error('MSAL initialization error:', error);
            }
        };
        initializeMsal();
    }, []);

    return (
        <AuthContext.Provider value={{ pca, initialized }}>
            {children}
        </AuthContext.Provider>
    );
};
