import React, { useState } from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import './DailySurveyChart.css';
import EditModal from './EditModal';
import { updateDailyQuestionResponse } from '../../../../services/dailyQuestionService';
import { useTranslation } from 'react-i18next';

const CustomTooltip = ({ active, payload, label, dataKey }) => {
    const { t } = useTranslation();

    if (active && payload && payload.length) {
        const data = payload[0].payload;
        let displayValue;

        switch (dataKey) {
            case 'wakeUpTime':
                displayValue = `${t('dailySurveyChart.wakeUpTimeChart')} : ${data.wakeUpTimeOriginal}`;
                break;
            case 'bedTime':
                displayValue = `${t('dailySurveyChart.bedTimeChart')} : ${data.bedTimeOriginal}`;
                break;
            case 'sleepQuality':
                displayValue = `${t('dailySurveyChart.sleepQualityChart')} : ${data.sleepQuality}`;
                break;
            default:
                displayValue = '';
        }

        return (
            <div className="custom-tooltip">
                <p>{label}</p>
                <p>{displayValue}</p>
            </div>
        );
    }
    return null;
};

const DailySurveyChart = ({ responses, noData, onUpdate }) => {
    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const parseTime = (timeStr) => {
        const [hours, minutes] = timeStr.split(':').map(Number);
        return hours + minutes / 60;
    };

    const formattedData = responses.map(response => ({
        date: new Date(response.date).toLocaleDateString(),
        wakeUpTime: parseTime(response.wakeUpTime),
        wakeUpTimeOriginal: response.wakeUpTime,
        bedTime: parseTime(response.bedTime),
        bedTimeOriginal: response.bedTime,
        sleepQuality: response.sleepQuality,
        id: response._id // Ensure we have the ID for updates
    }));

    const handleLineClick = (data) => {
        setSelectedData(data); // Set the clicked data point for editing
        setIsModalOpen(true); // Open the modal
    };

    const handleSave = async (updatedData) => {
        try {
            // Call the service to update the response in the backend
            await updateDailyQuestionResponse(updatedData.id, {
                wakeUpTime: updatedData.wakeUpTime,
                bedTime: updatedData.bedTime,
                sleepQuality: updatedData.sleepQuality,
            });

            // Notify parent to refresh data
            if (onUpdate) onUpdate();

            // Close the modal
            setIsModalOpen(false);
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    return (
        <div className={noData ? 'blurred' : ''}>
            {noData && (
                <div className="no-data-message">
                    <p>{t('dailySurveyChart.noDataMessage')}</p>
                </div>
            )}
            <h4>{t('dailySurveyChart.wakeUpTimeChart')}</h4>
            <ResponsiveContainer width="100%" height={200}>
                <LineChart data={formattedData} onClick={(e) => handleLineClick(e.activePayload?.[0]?.payload)}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip dataKey="wakeUpTime" />} />
                    <Legend />
                    <Line type="monotone" dataKey="wakeUpTime" stroke="#8884d8" name={t('dailySurveyChart.wakeUpTimeChart')} />
                </LineChart>
            </ResponsiveContainer>

            <h4>{t('dailySurveyChart.bedTimeChart')}</h4>
            <ResponsiveContainer width="100%" height={200}>
                <LineChart data={formattedData} onClick={(e) => handleLineClick(e.activePayload?.[0]?.payload)}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip dataKey="bedTime" />} />
                    <Legend />
                    <Line type="monotone" dataKey="bedTime" stroke="#82ca9d" name={t('dailySurveyChart.bedTimeChart')} />
                </LineChart>
            </ResponsiveContainer>

            <h4>{t('dailySurveyChart.sleepQualityChart')}</h4>
            <ResponsiveContainer width="100%" height={200}>
                <LineChart data={formattedData} onClick={(e) => handleLineClick(e.activePayload?.[0]?.payload)}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip dataKey="sleepQuality" />} />
                    <Legend />
                    <Line type="monotone" dataKey="sleepQuality" stroke="#ff7300" name={t('dailySurveyChart.sleepQualityChart')} />
                </LineChart>
            </ResponsiveContainer>

            {/* Modal for editing */}
            <EditModal
                isOpen={isModalOpen}
                data={selectedData}
                onClose={() => setIsModalOpen(false)}
                onSave={handleSave}
            />
        </div>
    );
};

export default DailySurveyChart;
