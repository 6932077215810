import React, { useState, useEffect } from 'react';
import { startOfWeek, endOfWeek } from 'date-fns';
import { Box, Card, CardContent, Grid, Typography, Button, IconButton } from '@mui/material';
import { CalendarToday, BarChart } from '@mui/icons-material'; // Import the BarChart icon
import SurveyProgress from '../SurveyProgress/SurveyProgress';
import ToDoList from '../ToDoList/ToDoList';
import DailySurveyChart from '../DailySurveyChart/DailySurveyChart';
import PerformanceChart from '../PerformanceChart';
import PerformanceChecklist from '../ObservationPeriod/PerformanceCheckList';
import ObservationPeriods from '../ObservationPeriod/ObservationPeriod';
import SurveyCalendar from '../SurveyProgress/SurveyCalendar';
import SurveyProgressBar from '../SurveyProgressBar/SurveyProgressBar'; // Import SurveyProgressBar
import './Overview.css';
import { useTranslation } from 'react-i18next';

const Overview = ({
    userId,
    setContent,
    dailySurveyResponses = [],
    performanceResponses = [],
    noData,
    isAdmin,
    refreshData,
}) => {
    const [currentWeek, setCurrentWeek] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false); // State to toggle calendar view
    const [showProgressBar, setShowProgressBar] = useState(false); // State to toggle progress bar view
    const { t } = useTranslation();

    const [refresh, setRefresh] = useState(0);
    useEffect(() => {
        const now = new Date();
        const minutesUntilNextHour = 60 - now.getMinutes();
        const millisecondsUntilNextHour = minutesUntilNextHour * 60 * 1000;

        const timeoutId = setTimeout(() => {
            setRefresh((prev) => prev + 1);

            const intervalId = setInterval(() => {
                setRefresh((prev) => prev + 1);
            }, 60 * 60 * 1000);

            return () => clearInterval(intervalId);
        }, millisecondsUntilNextHour);

        return () => clearTimeout(timeoutId);
    }, []);

    const weekStart = startOfWeek(currentWeek, { weekStartsOn: 1 });
    const weekEnd = endOfWeek(currentWeek, { weekStartsOn: 1 });
    const currentWeekResponses = performanceResponses.filter((response) => {
        const responseDate = new Date(response.timestamp);
        return responseDate >= weekStart && responseDate <= weekEnd;
    });

    const handlePeriodSelect = (period) => {
        setSelectedPeriod(period);
    };

    const handleReturnToObservationPeriods = () => {
        setSelectedPeriod(null);
    };

    return (
        <Box className="overview-container">
            <Typography variant="h4" className="overview-title" gutterBottom>
                {t('overview.dashboardOverview')}
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={refreshData}
                style={{ marginBottom: '20px' }}
            >
                {t('overview.refresh')}
            </Button>
            <Grid container className="grid-container" spacing={4}>
                {/* First Column: Survey Progress and ToDo List */}
                <Grid item xs={12} md={4} className="grid-item">
                    <Card elevation={3} className="card">
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h6">
                                    {t('overview.surveyProgress')}
                                </Typography>
                                <Box>
                                    <IconButton onClick={() => setShowCalendar(!showCalendar)}>
                                        <CalendarToday />
                                    </IconButton>
                                    <IconButton onClick={() => setShowProgressBar(!showProgressBar)}>
                                        <BarChart />
                                    </IconButton>
                                </Box>
                            </Box>
                            {showCalendar ? (
                                <SurveyCalendar
                                    surveyPeriods={[] /* Replace with actual survey periods */}
                                    onReturn={() => setShowCalendar(false)}
                                    userId={userId}
                                />
                            ) : showProgressBar ? (
                                <SurveyProgressBar userId={userId} onReturn={() => setShowProgressBar(false)} />
                            ) : (
                                <SurveyProgress userId={userId} key={refresh} />
                            )}
                        </CardContent>
                    </Card>

                    {/* New Card for Observation Periods */}
                    <Card elevation={3} className="card" style={{ marginTop: '20px' }}>
                        <CardContent>
                            {!selectedPeriod ? (
                                <ObservationPeriods userId={userId} onSelectPeriod={handlePeriodSelect} />
                            ) : (
                                <PerformanceChecklist
                                    userId={userId}
                                    startDate={selectedPeriod.start} // Pass start and end dates
                                    endDate={selectedPeriod.end}
                                    completedQuestions={selectedPeriod.completedQuestions}
                                    expectedQuestions={selectedPeriod.expectedQuestions}
                                    notifications={selectedPeriod.notifications}
                                    onReturn={handleReturnToObservationPeriods}
                                />
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4} className="grid-item">
                    <Card elevation={3} className="card">
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                {t('overview.dailySurveyChart')}
                            </Typography>
                            <DailySurveyChart responses={dailySurveyResponses} key={refresh} />
                        </CardContent>
                    </Card>
                </Grid>

                {/* Third Column: Performance Chart and Checklist */}
                <Grid item xs={12} md={4} className="grid-item">
                    <Card elevation={3} className="card">
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                {t('overview.performanceChart')}
                            </Typography>
                            <PerformanceChart
                                responses={currentWeekResponses}
                                currentWeek={currentWeek}
                                setCurrentWeek={setCurrentWeek}
                                key={refresh}
                            />
                        </CardContent>
                    </Card>

                    <Card elevation={3} className="card">
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                {t('overview.toDoList')}
                            </Typography>
                            <ToDoList
                                userId={userId}
                                setContent={setContent}
                                isAdmin={isAdmin}
                                key={refresh}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Overview;
