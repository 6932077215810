import React from 'react';
import downloadIcon from '../../../pic/female-doctor-6810748_1920.png';
import chronoType from '../../../pic/chronotype.png';
import research from '../../../pic/research.png';
import './Body.css';

const Body = () => {
    return (
        <>
            <div className="chronotype-section chronotype-section-1">
                <div className="chronotype-image">
                    <img src={chronoType} alt="Discover Your Chronotype" />
                </div>
                <div className="chronotype-content">
                    <h2>Discover Your Chronotype</h2>
                    <p>Uncover your natural rhythm and optimize your productivity. Our chronotype survey helps you understand your sleep patterns and daily energy levels. Join our community to improve your well-being.</p>
                    <button className="learn-more-btn">Learn more</button>
                </div>
            </div>
            <div className="chronotype-section chronotype-section-2">
                <div className="chronotype-content">
                    <h2>Download Our Chronotype Tracker App</h2>
                    <p>Get our app to easily participate in daily surveys, track your sleep patterns, and receive personalized insights. Stay connected and improve your daily productivity with ease.</p>
                    <button className="learn-more-btn">Learn more</button>
                </div>
                <div className="chronotype-image-2">
                    <img src={downloadIcon} alt="Discover Your Chronotype" />
                </div>
            </div>
            <div className="chronotype-section chronotype-section-3">
                <div className="chronotype-image-3">
                    <img src={research} alt="Discover Your Chronotype" />
                </div>
                <div className="chronotype-content">
                    <h2>Research and Insights</h2>
                    <p>Dive into the latest research on chronotypes and sleep patterns. Learn about the science behind our survey and how understanding your chronotype can lead to a healthier, more productive life.</p>
                    <button className="learn-more-btn">Learn more</button>
                </div>
            </div>
        </>
    );
}

export default Body;
