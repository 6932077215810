import React, { useEffect, useState } from 'react';
import { getAllUsers } from '../../../services/userService';
import './UserNotificationList.css';

const UserNotificationList = ({ selectedUsers, onUserSelect, searchTerm, setSearchTerm, ineligibleUsers, isTwoWeekNotification }) => {
    const [users, setUsers] = useState([]);
    const [currentUserId, setCurrentUserId] = useState(null);

    useEffect(() => {
        // Fetch the user from localStorage
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const user = JSON.parse(storedUser);
            setCurrentUserId(user._id);
        }

        const fetchUsers = async () => {
            try {
                const res = await getAllUsers();
                setUsers(res);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };
        fetchUsers();
    }, []);

    const filteredUsers = users
        .filter(user =>
            `${user.firstname} ${user.lastname} ${user.email}`.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .filter(user => user._id !== currentUserId); // Exclude the current user

    const handleUserSelect = (e) => {
        const { value, checked } = e.target;
        if (!isTwoWeekNotification || !ineligibleUsers.includes(value)) {
            onUserSelect(e);
        }
    };

    return (
        <div className="user-notification-list-container">
            <h2>Select Users for Notification</h2>
            <input
                type="text"
                placeholder="Search Users"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
            />
            <form>
                <table>
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Select</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUsers.map(user => {
                            const isDisabled = isTwoWeekNotification && ineligibleUsers.includes(user._id);
                            const isChecked = selectedUsers.includes(user._id) && !isDisabled;
                            return (
                                <tr key={user._id}>
                                    <td>
                                        {user.firstname} {user.lastname} ({user.email})
                                        {isDisabled && (
                                            <span className="tooltip">
                                                ⓘ
                                                <span className="tooltip-text">
                                                    This user is currently in a notification period. You cannot select them for a new two-week notification to avoid duplication.
                                                </span>
                                            </span>
                                        )}
                                    </td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            value={user._id}
                                            checked={isChecked}
                                            onChange={handleUserSelect}
                                            disabled={isDisabled}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </form>
        </div>
    );
};

export default UserNotificationList;
