import React, { useEffect, useState } from 'react';
import {
    Box, Button, TextField, Typography, Paper, List, ListItem, ListItemText, CircularProgress
} from '@mui/material';

const SurveyIntroduction = ({ user, onUpdate, onNext }) => {
    const [editMode, setEditMode] = useState(false);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        place: '',
        street: '',
        telephone: ''
    });
    const [errors, setErrors] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [formValid, setFormValid] = useState(false);

    useEffect(() => {
        if (user) {
            setFormData({
                firstName: user.firstname || '',
                lastName: user.lastname || '',
                email: user.email || '',
                place: user.place || '',
                street: user.street || '',
                telephone: user.telephone || ''
            });
        }
    }, [user]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000); // Update every second

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    useEffect(() => {
        validateForm();
    }, [formData]);

    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return date.toLocaleDateString(undefined, options);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = 'First Name is required';
        if (!formData.lastName) newErrors.lastName = 'Last Name is required';
        if (!formData.email) newErrors.email = 'Email is required';
        if (!formData.place) newErrors.place = 'Place is required';
        if (!formData.street) newErrors.street = 'Street is required';
        if (!formData.telephone) newErrors.telephone = 'Telephone is required';
        setErrors(newErrors);
        setFormValid(Object.keys(newErrors).length === 0);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formValid) {
            const updateFormData = new FormData();
            Object.keys(formData).forEach(key => {
                if (formData[key]) {
                    updateFormData.append(key, formData[key]);
                }
            });

            console.log('Form data being submitted:', formData);

            try {
                setIsSaving(true); // Start saving
                await onUpdate(updateFormData);
                alert('Profile updated successfully!');
                setEditMode(false); // Exit edit mode after updating
                setIsSaving(false); // Stop saving
                onNext(); // Proceed to next step
            } catch (error) {
                setIsSaving(false); // Stop saving on error
                console.error('Failed to update profile:', error);
                alert('Failed to update profile. Please check the console for more information.');
            }
        } else {
            console.log('Form validation failed:', errors);
        }
    };

    return (
        <Box sx={{ padding: 4, textAlign: 'center' }}>
            <Typography variant="h3" gutterBottom>Survey Instructions</Typography>
            <Typography variant="subtitle1" gutterBottom>Datum: {formatDate(currentDateTime)}</Typography>
            <Paper elevation={3} sx={{ padding: 4, marginTop: 2 }}>
                {editMode ? (
                    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            label="First Name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleInputChange}
                            error={!!errors.firstName}
                            helperText={errors.firstName}
                        />
                        <TextField
                            label="Last Name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleInputChange}
                            error={!!errors.lastName}
                            helperText={errors.lastName}
                        />
                        <TextField
                            label="Wohnort"
                            name="place"
                            value={formData.place}
                            onChange={handleInputChange}
                            error={!!errors.place}
                            helperText={errors.place}
                        />
                        <TextField
                            label="Adresse"
                            name="street"
                            value={formData.street}
                            onChange={handleInputChange}
                            error={!!errors.street}
                            helperText={errors.street}
                        />
                        <TextField
                            label="Tel"
                            name="telephone"
                            value={formData.telephone}
                            onChange={handleInputChange}
                            error={!!errors.telephone}
                            helperText={errors.telephone}
                        />
                        <TextField
                            label="Email"
                            name="email"
                            value={formData.email}
                            error={!!errors.email}
                            helperText={errors.email}
                            type="email"
                            disabled
                        />
                        <Button type="submit" variant="contained" color="primary" disabled={isSaving}>
                            {isSaving ? <CircularProgress size={24} /> : 'Save'}
                        </Button>
                    </Box>
                ) : (
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography>First Name: {user.firstname || 'Not provided'}</Typography>
                        <Typography>Last Name: {user.lastname || 'Not provided'}</Typography>
                        <Typography>Email: {user.email || 'Not provided'}</Typography>
                        <Typography>Wohnort: {user.place || 'Not provided'}</Typography>
                        <Typography>Adresse: {user.street || 'Not provided'}</Typography>
                        <Typography>Tel: {user.telephone || 'Not provided'}</Typography>
                        <Button variant="contained" color="primary" onClick={() => setEditMode(true)} sx={{ marginTop: 2 }}>
                            Edit
                        </Button>
                    </Box>
                )}

            </Paper>
            <Box sx={{ marginTop: 4 }}>
                <Paper elevation={3} sx={{ padding: 4 }}>
                    <Typography variant="h6" gutterBottom>Instructions</Typography>
                    <List>
                        <ListItem>
                            <ListItemText primary="Bitte lesen Sie jede Frage sorgfältig durch, bevor Sie antworten." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Beantworten Sie bitte alle Fragen, auch dann wenn Sie sich bei einer Frage unsicher sind." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Beantworten Sie die Fragen in der vorgegebenen Reihenfolge." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Beantworten Sie die Fragen so schnell wie möglich. Es sind die ersten Reaktionen auf die Fragen, die uns mehr interessieren als eine lange überlegte Antwort." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Beantworten Sie jede Frage ehrlich. Es gibt keine richtige oder falsche Antwort." />
                        </ListItem>
                    </List>
                    <Button variant="contained" color="secondary" onClick={onNext} disabled={!formValid || editMode || isSaving} sx={{ marginTop: 2 }}>
                        Next
                    </Button>
                </Paper>
            </Box>
        </Box>
    );
};

export default SurveyIntroduction;
