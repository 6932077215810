import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + '/performance';

export const savePerformanceResponse = async (userId, response) => {
    return await axios.post(`${API_URL}/save`, { userId, ...response });
};

export const getPerformanceResponses = async (userId) => {
    return await axios.get(`${API_URL}/${userId}`);
};
