import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import { fetchSurveyResponses } from '../../../../services/surveyService';
import './Result.css';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-client';
import { useAuth, AuthProvider } from './AuthProvider';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Card, CardContent, Typography, Box, Grid } from '@mui/material';

const Result = ({ userId }) => {
    const { t } = useTranslation(); // Use the useTranslation hook
    const { pca, initialized } = useAuth();
    const [totalScore, setTotalScore] = useState(null);
    const [responses, setResponses] = useState({});
    const [loading, setLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                if (!user || !user.token) {
                    throw new Error('No token found in localStorage');
                }
                setIsAdmin(user.isAdmin);

                const data = await fetchSurveyResponses(userId);
                if (data) {
                    setResponses(data);
                    const total = Object.values(data).reduce((acc, cur) => acc + (cur.score || 0), 0);
                    setTotalScore(total);
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchResponses();
    }, [userId]);

    const getChronotype = (score) => {
        if (score >= 14 && score <= 30) return 'Definitiver Abendtyp';
        if (score >= 31 && score <= 41) return 'Moderater Abendtyp';
        if (score >= 42 && score <= 58) return 'Neutraltyp';
        if (score >= 59 && score <= 69) return 'Moderater Morgentyp';
        if (score >= 70 && score <= 86) return 'Definitiver Morgentyp';
        return 'Unknown';
    };

    const getRecommendations = (chronotype) => {
        return {
            sleep: t(`result.recommendations.${chronotype}.sleep`),
            wake: t(`result.recommendations.${chronotype}.wake`),
            tips: t(`result.recommendations.${chronotype}.tips`)
        };
    };

    const questions = {
        q1: t('result.questions.q1'),
        q2: t('result.questions.q2'),
        q3: t('result.questions.q3'),
        q4: t('result.questions.q4'),
        q5: t('result.questions.q5'),
        q6: t('result.questions.q6'),
        q7: t('result.questions.q7'),
        q8: t('result.questions.q8'),
        q9: t('result.questions.q9'),
        q10: t('result.questions.q10'),
        q11: t('result.questions.q11'),
        q12: t('result.questions.q12'),
        q13: t('result.questions.q13'),
        q14: t('result.questions.q14'),
        q15: t('result.questions.q15'),
        q16: t('result.questions.q16'),
        q17: t('result.questions.q17'),
        q18: t('result.questions.q18'),
        q19: t('result.questions.q19')
    };

    const renderResponse = (questionKey, response) => {
        const questionText = questions[questionKey];
        if (response.time) {
            return (
                <div key={questionKey} className="response-container">
                    <p><strong>{questionText}</strong></p>
                    <p>Zeit: {response.time}, Punktzahl: {response.score}</p>
                </div>
            );
        } else if (response.description) {
            return (
                <div key={questionKey} className="response-container">
                    <p><strong>{questionText}</strong></p>
                    <p>Beschreibung: {response.description}, Punktzahl: {response.score}</p>
                </div>
            );
        }
        return null;
    };

    const createCalendarEvents = async () => {
        if (!initialized) {
            console.error('MSAL not initialized');
            return;
        }

        setLoading(true);

        try {
            const accounts = pca.getAllAccounts();
            if (accounts.length === 0) {
                await pca.loginPopup();
            }
            const account = pca.getAllAccounts()[0];
            let tokenResponse;

            try {
                tokenResponse = await pca.acquireTokenSilent({
                    scopes: ["Calendars.ReadWrite", "User.Read"],
                    account: account
                });
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    tokenResponse = await pca.acquireTokenPopup({
                        scopes: ["Calendars.ReadWrite", "User.Read"],
                        account: account
                    });
                } else {
                    throw error;
                }
            }

            const client = MicrosoftGraph.Client.init({
                authProvider: (done) => {
                    done(null, tokenResponse.accessToken);
                }
            });

            const calendars = await client.api('/me/calendars').get();
            let calendarId = null;

            for (const calendar of calendars.value) {
                if (calendar.name === 'Survey Notifications') {
                    calendarId = calendar.id;
                    break;
                }
            }

            if (!calendarId) {
                const newCalendar = await client.api('/me/calendars').post({
                    name: 'Survey Notifications'
                });
                calendarId = newCalendar.id;
            }

            const events = [];
            const start = new Date();
            start.setHours(8, 0, 0, 0);

            const dayOfWeek = start.getDay();
            if (dayOfWeek !== 1) { // 1 = Monday
                start.setDate(start.getDate() + ((1 + 7 - dayOfWeek) % 7));
            }

            for (let week = 0; week < 2; week++) {
                for (let day = 0; day < 5; day++) {
                    const dayStart = new Date(start);
                    dayStart.setDate(start.getDate() + (week * 7) + day);

                    for (let hour = 8; hour <= 17; hour++) {
                        const eventStart = new Date(dayStart);
                        eventStart.setHours(hour);

                        const eventEnd = new Date(eventStart);
                        eventEnd.setHours(eventStart.getHours() + 1);

                        events.push({
                            subject: t('result.addNotifications'),
                            start: {
                                dateTime: eventStart.toISOString(),
                                timeZone: 'UTC'
                            },
                            end: {
                                dateTime: eventEnd.toISOString(),
                                timeZone: 'UTC'
                            },
                            body: {
                                contentType: 'HTML',
                                content: `Please answer the performance question by clicking <a href="https://www.you-flow.de/login">here</a>.`
                            }
                        });
                    }
                }
            }

            for (const event of events) {
                await client.api(`/me/calendars/${calendarId}/events`).post(event);
            }

            alert(t('result.calendarAdded'));
        } catch (error) {
            console.error('Error creating calendar events:', error);
            alert(t('result.calendarFailed'));
        } finally {
            setLoading(false);
        }
    };

    const chronotype = getChronotype(totalScore);
    const recommendations = getRecommendations(chronotype);

    return (
        <div className="result-container">
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <Card className="result-card">
                        <CardContent>
                            <Typography variant="h6">{t('result.totalScore')}</Typography>
                            <Typography variant="h4">{totalScore}</Typography>
                        </CardContent>
                    </Card>
                    <Card className="result-card">
                        <CardContent>
                            <Typography variant="h6">{t('result.chronotype')}</Typography>
                            <Typography variant="h4">{chronotype}</Typography>
                        </CardContent>
                    </Card>
                    <Card className="result-card">
                        <CardContent>
                            {/* <Typography variant="h6">{t('result.recommendedTimes')}</Typography> */}
                            <Typography><strong>{t('result.sleepTime')}</strong> {recommendations.sleep}</Typography>
                            <Typography><strong>{t('result.wakeTime')}</strong> {recommendations.wake}</Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={9}>
                    <Card className="right-column-card">
                        <CardContent>
                            <Typography variant="h4">{t('result.questionnaireResults')}</Typography>
                            {isAdmin && (
                                <>
                                    <Typography variant="h5">{t('result.questionnaireHistory')}</Typography>
                                    {Object.keys(responses).length > 0 ? (
                                        Object.keys(responses).map(question => renderResponse(question, responses[question]))
                                    ) : (
                                        <Typography>{t('result.noResponsesFound')}</Typography>
                                    )}
                                </>
                            )}
                            {!isAdmin && (
                                <div className="chronotype-description">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={createCalendarEvents}
                                        disabled={loading}
                                        startIcon={loading && <CircularProgress size={20} />}
                                    >
                                        {loading ? t('result.addingToCalendar') : t('result.addNotifications')}
                                    </Button> <br></br> <br></br>
                                    <Typography>{t('result.thankYouMessage')}</Typography> <br></br>
                                    <Typography>{t('result.chronotypeScale', { chronotype })}</Typography> <br></br>
                                    <Typography>{t('result.internalClockDescription')}</Typography>  <br></br>
                                    <Typography>{t('result.morningTypeDescription')}</Typography> <br></br>
                                    <Typography>{t('result.eveningTypeDescription')}</Typography> <br></br>
                                    <Card className="result-card">
                                        <CardContent>
                                            <Typography><strong>{t('result.practicalRecommendations')}</strong> {recommendations.tips}</Typography>
                                        </CardContent>
                                    </Card>
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

const WrappedResult = (props) => (
    <AuthProvider>
        <Result {...props} />
    </AuthProvider>
);

export default WrappedResult;
