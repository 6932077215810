import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import UserNotificationList from './UserNotificationList';
import { getAllUsers, getNotificationAssignedAt } from '../../../services/userService';
import { createNotification } from '../../../services/notificationsService';
import { addDays, isBefore } from 'date-fns';
import './SetNotification.css';

const SetNotification = ({ userId, onBack }) => {
    const [notificationData, setNotificationData] = useState({
        title: '',
        message: '',
        date: '',
        time: '',
        frequency: '',
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: ''
    });
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isRecurring, setIsRecurring] = useState(false);
    const [isTwoWeekNotification, setIsTwoWeekNotification] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showUserPopup, setShowUserPopup] = useState(false);
    const [errors, setErrors] = useState({});
    const [users, setUsers] = useState([]);
    const [lastNotificationDate, setLastNotificationDate] = useState(null);
    const [ineligibleUsers, setIneligibleUsers] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const res = await getAllUsers();
                setUsers(res);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };
        if (!userId) {
            fetchUsers();
        }
    }, [userId]);

    // Check for ineligible users when the modal opens and users are loaded
    useEffect(() => {
        const fetchLastNotificationDates = async () => {
            try {
                if (users && users.length > 0) {
                    let ineligibleUsersList = [];

                    for (const user of users) {
                        const notifications = await getNotificationAssignedAt(user._id);

                        if (notifications.length > 0) {
                            const lastDate = new Date(Math.max(...notifications.map(date => new Date(date))));
                            const nextAllowedDate = addDays(lastDate, 14);

                            if (isBefore(new Date(), nextAllowedDate)) {
                                ineligibleUsersList.push(user._id);
                            }
                        }
                    }

                    setIneligibleUsers(ineligibleUsersList);
                }
            } catch (error) {
                console.error("Error fetching last notification dates:", error);
            }
        };

        if (showUserPopup) {
            fetchLastNotificationDates();
        }
    }, [showUserPopup, users]);


    const handleNotificationTypeChange = (type) => {
        setIsRecurring(false);
        setIsTwoWeekNotification(type === 'twoWeek');

        if (type === 'twoWeek') {
            // Unselect ineligible users for two-week notifications
            setSelectedUsers(prevSelected =>
                prevSelected.filter(userId => !ineligibleUsers.includes(userId))
            );
        }
    };




    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNotificationData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleUserSelect = (e) => {
        const { value, checked } = e.target;
        setSelectedUsers(prevSelected =>
            checked ? [...prevSelected, value] : prevSelected.filter(id => id !== value)
        );
    };

    const handleConfirmUsers = () => {
        setShowUserPopup(false);
        // Here, we just close the modal after confirming the selected users
    };

    const validateForm = () => {
        const newErrors = {};
        if (!isTwoWeekNotification) { // Skip validation if two-week notification is selected
            if (!notificationData.title) newErrors.title = 'Title is required';
            if (!notificationData.message) newErrors.message = 'Message is required';
            if (isRecurring) {
                if (!notificationData.startDate) newErrors.startDate = 'Start date is required';
                if (!notificationData.startTime) newErrors.startTime = 'Start time is required';
                if (!notificationData.endDate) newErrors.endDate = 'End date is required';
                if (!notificationData.endTime) newErrors.endTime = 'End time is required';
                if (!notificationData.frequency) newErrors.frequency = 'Frequency is required';
            } else {
                if (!notificationData.date) newErrors.date = 'Date is required';
                if (!notificationData.time) newErrors.time = 'Time is required';
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (selectedUsers.length === 0 && !userId) {
            alert('Please select at least one user.');
            return;
        }
        if (validateForm()) {
            setShowConfirmation(true);
        } else {
            alert('Please fill in all required fields.');
        }
    };

    const handleConfirm = async () => {
        const targetUsers = userId ? [userId] : selectedUsers;

        // Verification for the two-week notification period
        if (isTwoWeekNotification && lastNotificationDate) {
            console.log("last date: ", lastNotificationDate)
            const nextAllowedDate = addDays(lastNotificationDate, 14);
            console.log("nextAllowedDate: ", nextAllowedDate)
            if (isBefore(new Date(), nextAllowedDate)) {
                alert(`You can only set a new two-week notification period after ${nextAllowedDate.toDateString()}.`);
                return;
            }
        }

        const notificationPayload = isTwoWeekNotification
            ? {
                userIds: targetUsers,
                isTwoWeekNotification,  // Indicate that it's a two-week notification period
            }
            : {
                title: notificationData.title,
                message: notificationData.message,
                userIds: targetUsers,
                dateTime: isRecurring ? undefined : `${notificationData.date}T${notificationData.time}`,
                isRecurring,
                frequency: isRecurring ? notificationData.frequency : undefined,
                startDateTime: isRecurring ? `${notificationData.startDate}T${notificationData.startTime}` : undefined,
                endDateTime: isRecurring ? `${notificationData.endDate}T${notificationData.endTime}` : undefined,
            };

        try {
            const response = await createNotification(notificationPayload); // Send the payload to the backend
            alert('Notification(s) scheduled successfully!');
        } catch (error) {
            console.error('Error setting notification:', error);
            alert('Failed to set notification. Please try again.');
        }

        setShowConfirmation(false);
        onBack();
    };



    const handleCancel = () => {
        setShowConfirmation(false);
    };

    const getUserDetails = (userId) => {
        if (!users || users.length === 0) {
            return 'Unknown User';
        }
        const user = users.find(user => user._id === userId);
        return user ? `${user.firstname} ${user.lastname} (${user.email})` : 'Unknown User';
    };

    return (
        <div className="form-container">
            <h3>Set Notification {userId ? 'for User' : 'for Users'}</h3>
            <form onSubmit={handleSubmit}>
                <label>
                    Title:
                    <input
                        type="text"
                        name="title"
                        value={notificationData.title}
                        onChange={handleInputChange}
                        disabled={isTwoWeekNotification} // Disable when two-week notification is selected
                    />
                    {errors.title && <span style={{ color: 'red' }}>{errors.title}</span>}
                </label>
                <label>
                    Message:
                    <textarea
                        name="message"
                        value={notificationData.message}
                        onChange={handleInputChange}
                        disabled={isTwoWeekNotification} // Disable when two-week notification is selected
                    />
                    {errors.message && <span style={{ color: 'red' }}>{errors.message}</span>}
                </label>
                <label>
                    <input
                        type="radio"
                        name="notificationType"
                        value="oneTime"
                        checked={!isRecurring && !isTwoWeekNotification}
                        onChange={() => handleNotificationTypeChange('oneTime')}
                    />
                    One-Time Notification
                </label>
                <label>
                    <input
                        type="radio"
                        name="notificationType"
                        value="twoWeek"
                        checked={isTwoWeekNotification}
                        onChange={() => handleNotificationTypeChange('twoWeek')}
                    />
                    Two-Week Notification Period
                </label>

                {isRecurring && !isTwoWeekNotification ? (
                    <>
                        <label>
                            Start Date:
                            <input
                                type="date"
                                name="startDate"
                                value={notificationData.startDate}
                                onChange={handleInputChange}
                            />
                            {errors.startDate && <span style={{ color: 'red' }}>{errors.startDate}</span>}
                        </label>
                        <label>
                            Start Time:
                            <input
                                type="time"
                                name="startTime"
                                value={notificationData.startTime}
                                onChange={handleInputChange}
                            />
                            {errors.startTime && <span style={{ color: 'red' }}>{errors.startTime}</span>}
                        </label>
                        <label>
                            End Date:
                            <input
                                type="date"
                                name="endDate"
                                value={notificationData.endDate}
                                onChange={handleInputChange}
                            />
                            {errors.endDate && <span style={{ color: 'red' }}>{errors.endDate}</span>}
                        </label>
                        <label>
                            End Time:
                            <input
                                type="time"
                                name="endTime"
                                value={notificationData.endTime}
                                onChange={handleInputChange}
                            />
                            {errors.endTime && <span style={{ color: 'red' }}>{errors.endTime}</span>}
                        </label>
                        <label>
                            Frequency:
                            <select
                                name="frequency"
                                value={notificationData.frequency}
                                onChange={handleInputChange}
                            >
                                <option value="">Select Frequency</option>
                                <option value="1">Every 1 Minute</option>
                                <option value="60">Every 1 Hour</option>
                                <option value="120">Every 2 Hours</option>
                                <option value="240">Every 4 Hours</option>
                            </select>
                            {errors.frequency && <span style={{ color: 'red' }}>{errors.frequency}</span>}
                        </label>
                    </>
                ) : !isTwoWeekNotification ? (
                    <>
                        <label>
                            Date:
                            <input
                                type="date"
                                name="date"
                                value={notificationData.date}
                                onChange={handleInputChange}
                            />
                            {errors.date && <span style={{ color: 'red' }}>{errors.date}</span>}
                        </label>
                        <label>
                            Time:
                            <input
                                type="time"
                                name="time"
                                value={notificationData.time}
                                onChange={handleInputChange}
                            />
                            {errors.time && <span style={{ color: 'red' }}>{errors.time}</span>}
                        </label>
                    </>
                ) : null}

                <>
                    <button type="button" onClick={() => setShowUserPopup(true)}>
                        Select Users
                    </button> &nbsp;  &nbsp;
                    <Modal isOpen={showUserPopup} onClose={() => setShowUserPopup(false)} onConfirm={handleConfirmUsers}>
                        <UserNotificationList
                            selectedUsers={selectedUsers}
                            onUserSelect={handleUserSelect}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            users={users}
                            onConfirm={handleConfirmUsers}
                            ineligibleUsers={ineligibleUsers}
                            isTwoWeekNotification={isTwoWeekNotification}
                        />
                    </Modal>
                </>

                <button type="submit">Set Notification</button>  &nbsp;
                <button type="button" onClick={onBack}>Back</button>
            </form>

            {showConfirmation && (
                <div className="confirmation-popup">
                    <div className="popup-content">
                        <h4>Confirm Notification</h4>
                        <p>Title: {notificationData.title}</p>
                        <p>Message: {notificationData.message}</p>
                        {isRecurring ? (
                            <>
                                <p>Start: {notificationData.startDate} {notificationData.startTime}</p>
                                <p>End: {notificationData.endDate} {notificationData.endTime}</p>
                                <p>Frequency: Every {notificationData.frequency} {notificationData.frequency === '1' ? 'minute' : 'hours'}</p>
                            </>
                        ) : isTwoWeekNotification ? (
                            <p>Two-week notification period selected</p>
                        ) : (
                            <p>Date and Time: {notificationData.date} {notificationData.time}</p>
                        )}
                        <h4>Users:</h4>
                        <ul>
                            {selectedUsers.map(userId => (
                                <li key={userId}>{getUserDetails(userId)}</li>
                            ))}
                        </ul>
                        <button onClick={handleConfirm}>Confirm</button> &nbsp;&nbsp;&nbsp;&nbsp;
                        <button onClick={handleCancel}>Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SetNotification;