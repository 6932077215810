import React, { useState } from 'react';
import { TextField, Button, Card } from '@mui/material';
import { toast } from 'react-toastify';
import { sendPasswordResetEmail } from '../../../services/authService';
import './ForgotPassword.css';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');

    const handleInputChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordReset = async (event) => {
        event.preventDefault();
        try {
            await sendPasswordResetEmail(email);
            toast.success('Password reset link has been sent to your email.');
        } catch (error) {
            console.error('Error sending password reset email:', error);
            toast.error('Failed to send password reset email.');
        }
    };

    return (
        <Card className="forgot-password-card">
            <form onSubmit={handlePasswordReset}>
                <TextField
                    label="Your Email"
                    type="email"
                    value={email}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    className="forgot-password-input"
                /><br></br> <br></br>
                <Button type="submit" variant="contained" className="forgot-password-button">
                    Send Password Reset Link
                </Button>
            </form>
        </Card>
    );
};

export default ForgotPassword;
