import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Q2 = ({ userId, onNext, onPrevious, saveResponse }) => {
    const [value, setValue] = useState(20);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchExistingResponse = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                if (!user || !user.token) {
                    throw new Error('No token found in localStorage');
                }
                const token = user.token;
                const baseUrl = process.env.REACT_APP_API_URL;

                const response = await axios.get(`${baseUrl}/survey/get-response/q2/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (response.data && response.data.time) {
                    const hours = parseInt(response.data.time.split(':')[0]);
                    const minutes = parseInt(response.data.time.split(':')[1].split(' ')[0]);
                    const period = response.data.time.split(' ')[1];
                    let timeInHours = period === 'pm' && hours !== 12 ? hours + 12 : hours;
                    if (period === 'am' && hours === 12) timeInHours = 24;
                    if (period === 'am' && timeInHours < 12) timeInHours += 24;
                    const time = timeInHours + minutes / 60;

                    setValue(time);
                }
            } catch (error) {
                console.error('Error fetching existing response:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchExistingResponse();
    }, [userId]);

    const getScore = (time) => {
        if (time >= 20 && time < 21) return 5;
        if (time >= 21 && time < 22) return 4;
        if (time >= 22 && time < 23) return 3;
        if (time >= 23 && time < 24) return 2;
        if (time >= 24 && time < 27) return 1;
        return 0;
    };

    const formatTime = (time) => {
        let hours = Math.floor(time);
        const minutes = Math.round((time % 1) * 60);
        let period = hours >= 24 || hours < 12 ? 'am' : 'pm';

        if (hours >= 24) {
            hours -= 24;
        }

        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        return `${formattedHours}:${minutes === 0 ? '00' : minutes} ${period}`;
    };

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const handleNext = () => {
        const time = formatTime(value);
        const score = getScore(value);
        saveResponse('q2', { time, score });
        onNext();
    };

    if (loading) {
        return <p>{t('questions.q2.loading')}</p>;
    }

    return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
            <h3>{t('questions.q2.title')}</h3>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span>20:00</span>
                <Slider
                    min={20}
                    max={27}
                    marks={{
                        20: '20:00',
                        21: '21:00',
                        22: '22:00',
                        23: '23:00',
                        24: '00:00',
                        25: '1:00',
                        26: '2:00',
                        27: '3:00'
                    }}
                    step={0.25}
                    value={value}
                    onChange={handleChange}
                    style={{ width: 400, margin: '0 10px' }}
                />
                <span>3:00</span>
            </div>
            <p>{t('questions.q2.selectedTime')}: {formatTime(value)} ({t('questions.q2.score')}: {getScore(value)})</p>
            <button onClick={onPrevious} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}>{t('questions.q2.previous')}</button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button onClick={handleNext} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}>{t('questions.q2.next')}</button>
        </div>
    );
};

export default Q2;
