import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import LoginApp from './components/Login/Login';
import Dashboard from './components/Dashboard/UserDashboard/UserDashboard';
import AdminDashboard from './components/Dashboard/AdminDashboard/AdminDashboard';
import UserDetail from './components/Dashboard/AdminDashboard/UserDetail';
import UserPerformance from './components/Dashboard/AdminDashboard/UserPerformance';
import PrivateRoute from './components/PrivateRoute';
import { NotificationProvider } from './components/Notification/NotificationContext';
import { useNotifications } from './components/Notification/NotificationContext';
import Header from './components/HomePage/Header/Header';
import HeroSection from './components/HomePage/HeroSection/HeroSection';
import Services from './components/HomePage/Services/Services';
import Body from './components/HomePage/Body/Body';
import AboutMe from './components/HomePage/AboutMe/AboutMe';
import Footer from './components/HomePage/Footer/Footer';
import NavBar from './components/Dashboard/NavBar';
import { checkSurveyCompletion } from './services/surveyService';
import { getUserProfile } from './services/userService';
import { checkTokenValidity } from './services/authService';
import { UserContext } from './contexts/UserContext';
import { Toolbar } from '@mui/material';
import { setNavigate, setModalHandler, setUserHandler, handleSessionExpired } from './components/SessionExpiredHandler';
import { getDailyQuestionResponses } from './services/dailyQuestionService';
import ForgotPassword from './components/Login/ForgotPassword/ForgotPassword';
import ResetPassword from './components/Login/ResetPassword/ResetPassword';
import SessionExpiredModal from './components/SessionExpiredModal';


const AppContent = ({ content, setContent }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [surveyCompleted, setSurveyCompleted] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [isDailySurveyDone, setIsDailySurveyDone] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { user, setUser, login } = useContext(UserContext);
    const { checkAndAddNotification, markDailySurveyAsDone } = useNotifications();
    console.log(user)
    console.log(content)

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const storedUser = JSON.parse(localStorage.getItem('user'));
                const token = storedUser?.token;

                if (!token) {
                    throw new Error('No token found in localStorage');
                }

                const userProfile = await getUserProfile(token);

                const { firstname, email, username, avatar, avatarContentType, _id, isAdmin, place, street, telephone, lastname } = userProfile;
                const completeUser = { firstname, email, username, avatar, avatarContentType, token, _id, isAdmin, place, street, telephone, lastname };
                setUser(completeUser);

                if (!isAdmin) {
                    const isSurveyCompleted = await checkSurveyCompletion(_id);
                    setSurveyCompleted(isSurveyCompleted);

                    if (!isSurveyCompleted) {
                        setContent('surveyIntroduction');
                    } else {
                        setContent('overview');
                    }

                    // Check for daily question responses
                    try {
                        const dailyResponses = await getDailyQuestionResponses(_id);
                        const today = new Date().toISOString().split('T')[0];
                        const responses = Object.values(dailyResponses.data);

                        const hasTodayResponse = responses.some(response => {
                            const responseDate = new Date(response.date).toISOString().split('T')[0];
                            return responseDate === today;
                        });

                        if (!isSurveyCompleted && responses.length === 0) {
                            console.log('Survey not completed and no daily responses found. Skipping notification.');
                        } else if (!hasTodayResponse) {
                            checkAndAddNotification(); // Trigger notification handling in context
                        } else {
                            setIsDailySurveyDone(true);
                            markDailySurveyAsDone();
                        }
                    } catch (error) {
                        console.error('Error fetching daily question responses:', error);
                    }
                } else {
                    setContent('personalInfo');
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    handleSessionExpired();
                    setModalOpen(true); // Show the session expired modal
                } else {
                    console.error("Error fetching initial data:", error);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchInitialData();
    }, [loggedIn, setUser, setContent, checkAndAddNotification, markDailySurveyAsDone]);


    useEffect(() => {
        setNavigate(navigate);
        setModalHandler(setModalOpen);
        setUserHandler(setUser);
    }, [navigate, setUser]);

    useEffect(() => {
        const intervalId = setInterval(async () => {
            if (user) {
                const isValid = await checkTokenValidity();
                if (!isValid) {
                    clearInterval(intervalId); // Stop polling if the token is invalid
                }
            }
        }, 10800000); // 3 hours

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [user]);

    const onAnswerQuestion = (notification) => {
        console.log(notification.type);
        if (location.pathname !== '/dashboard') {
            navigate('/dashboard', { replace: true });
            // Use a slight delay to ensure navigation is complete before setting content
            setTimeout(() => {
                if (notification.type === 'hourly') {
                    setContent('performanceQuestion');
                } else if (notification.type === 'daily') {
                    setContent('DailySurvey');
                }
            }, 100);
        } else {
            if (notification.type === 'hourly') {
                setContent('performanceQuestion');
            } else if (notification.type === 'daily') {
                setContent('DailySurvey');
            }
        }
    };

    const handleMenuItemClick = (item) => {
        setContent(item);
        setDrawerOpen(false);
    };

    const handleSurveyCompletion = () => {
        setSurveyCompleted(true);
        markDailySurveyAsDone();
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <NotificationProvider user={user} navigate={navigate} onAnswerQuestion={onAnswerQuestion} setContent={setContent} content={content} >
            <div className="App">
                {user ? (
                    <>
                        <NavBar
                            setDrawerOpen={setDrawerOpen}
                            drawerOpen={drawerOpen}
                            handleMenuItemClick={handleMenuItemClick}
                            surveyCompleted={surveyCompleted}

                        />
                        <Toolbar />
                    </>
                ) : (
                    <Header />
                )}
                <Routes>
                    <Route path="/" element={<><HeroSection /><Services /><Body /><AboutMe /></>} />
                    <Route path="/login" element={<LoginApp setContent={setContent} setLoggedIn={setLoggedIn} />} />
                    <Route path="/dashboard" element={<PrivateRoute><Dashboard content={content} setContent={setContent} onAnswerQuestion={onAnswerQuestion} onSurveyCompletion={handleSurveyCompletion} /></PrivateRoute>} />
                    <Route path="/admin-dashboard" element={<PrivateRoute adminOnly={true}><AdminDashboard content={content} setContent={setContent} /></PrivateRoute>} />
                    <Route path="/admin/user/:userId" element={<PrivateRoute adminOnly={true}><UserDetail /></PrivateRoute>} />
                    <Route path="/admin/user/:userId/performance" element={<PrivateRoute adminOnly={true}><UserPerformance /></PrivateRoute>} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password/:token" element={<ResetPassword />} />
                </Routes>
                <Footer />
                <SessionExpiredModal open={modalOpen} handleClose={() => setModalOpen(false)} />
            </div>
        </NotificationProvider>
    );
};

export default AppContent;
