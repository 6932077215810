import React, { useState, useEffect } from 'react';
import './NoteManager.css';
import { getAllNotes, addNoteToUser, updateNoteForUser, deleteNoteForUser } from '../../../../services/userService';

const NoteManager = ({ user }) => {
    const [notes, setNotes] = useState([]);
    const [noteText, setNoteText] = useState('');
    const [editingNoteId, setEditingNoteId] = useState(null);

    useEffect(() => {
        const fetchNotes = async () => {
            try {
                const userNotes = await getAllNotes(user._id);
                setNotes(userNotes);
            } catch (error) {
                console.error('Error fetching notes:', error);
                setNotes([]); // Ensure we set notes to an empty array on error
            }
        };

        fetchNotes();
    }, [user._id]);

    const handleAddNote = async () => {
        if (noteText.trim() === '') return;
        try {
            const updatedNotes = await addNoteToUser(user._id, noteText);
            setNotes(updatedNotes);
            setNoteText('');
        } catch (error) {
            console.error('Error adding note:', error);
        }
    };

    const handleUpdateNote = async () => {
        if (noteText.trim() === '') return;
        try {
            const updatedNote = await updateNoteForUser(user._id, editingNoteId, noteText);
            setNotes(notes.map(note => note._id === editingNoteId ? updatedNote : note));
            setNoteText('');
            setEditingNoteId(null);
        } catch (error) {
            console.error('Error updating note:', error);
        }
    };

    const handleDeleteNote = async (noteId) => {
        try {
            const updatedNotes = await deleteNoteForUser(user._id, noteId);
            setNotes(updatedNotes);
        } catch (error) {
            console.error('Error deleting note:', error);
        }
    };

    const startEditing = (noteId, currentText) => {
        setEditingNoteId(noteId);
        setNoteText(currentText);
    };

    return (
        <div className="note-manager">
            <h2>Manage Notes for {user.firstname}</h2>
            <div className="note-input">
                <textarea
                    value={noteText}
                    onChange={(e) => setNoteText(e.target.value)}
                    placeholder="Enter your note..."
                />
                {editingNoteId ? (
                    <button onClick={handleUpdateNote} className="btn update-btn">Update Note</button>
                ) : (
                    <button onClick={handleAddNote} className="btn add-btn">Add Note</button>
                )}
            </div>
            <div className="note-list">
                {notes.length > 0 ? (
                    notes.map(note => (
                        <div key={note._id} className="note-item">
                            <p>{note.comments}</p>
                            <small>
                                Added on: {new Date(note.createdAt).toLocaleDateString()}
                                {note.updatedAt !== note.createdAt && ` (Last modified on: ${new Date(note.updatedAt).toLocaleDateString()})`}
                            </small>
                            <div className="note-actions">
                                <button onClick={() => startEditing(note._id, note.comments)} className="btn edit-btn">Edit</button>
                                <button onClick={() => handleDeleteNote(note._id)} className="btn delete-btn">Delete</button>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No notes available.</p>
                )}
            </div>
        </div>
    );
};

export default NoteManager;
