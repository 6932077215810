import { logout } from "../services/authService";
import { UserContext } from '../contexts/UserContext';

let navigate;
let setModalOpen;
let setUser;

export const setNavigate = (nav) => {
    navigate = nav;
};

export const setModalHandler = (setOpen) => {
    setModalOpen = setOpen;
};

export const setUserHandler = (setUserFunc) => {
    setUser = setUserFunc;
};

export const handleSessionExpired = () => {
    logout();
    localStorage.clear();

    // Set user to null in context
    if (setUser) {
        setUser(null);
    }

    // Show the session expired modal
    if (setModalOpen) {
        setModalOpen(true);
    }
};

export const handleCloseModal = () => {
    if (setModalOpen) {
        setModalOpen(false);
    }
    if (navigate) {
        console.log('Navigating to login page');
        navigate('/login');
    } else {
        console.log('Navigate function is not defined');
    }
};
