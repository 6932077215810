import React, { useState, useEffect } from 'react';
import { Box, ListItem, ListItemText, Typography, Button, Grid, Modal } from '@mui/material';
import { getPerformanceResponses } from '../../../../services/performanceService';
import PerformanceQuestion from '../../UserDashboard/DailySurveyAndPerformanceQuestion/PerformanceQuestion/PerformanceQuestion';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

const PerformanceChecklist = ({ userId, startDate, endDate, notifications, onReturn }) => {
    const [performanceResponses, setPerformanceResponses] = useState([]);
    const [dailyTimeSlots, setDailyTimeSlots] = useState([]);
    const [currentDayIndex, setCurrentDayIndex] = useState(0);
    const [selectedTime, setSelectedTime] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const timeZone = 'Europe/Berlin';
    const { t } = useTranslation();

    useEffect(() => {
        const fetchPerformanceData = async () => {
            try {
                const response = await getPerformanceResponses(userId);

                const performanceData = (response.data || []).map((entry) =>
                    moment(entry.timestamp).tz(timeZone)
                );
                setPerformanceResponses(performanceData);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    console.warn('No performance responses found for user.');
                    setPerformanceResponses([]);
                } else {
                    console.error('Error fetching performance responses:', error);
                }
            }
        };

        fetchPerformanceData();
    }, [userId, timeZone]);

    useEffect(() => {
        const daysInPeriod = [];
        let currentDay = moment(startDate).tz(timeZone).startOf('day');

        while (currentDay.isSameOrBefore(moment(endDate).tz(timeZone), 'day')) {
            const timeSlotsForDay = notifications
                .filter((notif) => moment(notif).isSame(currentDay, 'day'))
                .map((notif) => notif.format('HH:mm'));

            if (timeSlotsForDay.length > 0) {
                daysInPeriod.push({
                    date: currentDay.format('YYYY-MM-DD'),
                    timeSlots: timeSlotsForDay,
                });
            }

            currentDay = currentDay.add(1, 'day');
        }

        setDailyTimeSlots(daysInPeriod);

        const todayIndex = daysInPeriod.findIndex((day) =>
            moment().tz(timeZone).isSame(moment(day.date).tz(timeZone), 'day')
        );

        if (todayIndex !== -1) {
            setCurrentDayIndex(todayIndex);
        }

    }, [startDate, endDate, notifications, timeZone]);

    const isHourCompleted = (date, time) => {
        const [hour, minute] = time.split(':').map(Number);
        const slotDateTime = moment(date).tz(timeZone).hour(hour).minute(minute);
        return performanceResponses.some((response) => response.isSame(slotDateTime, 'hour'));
    };

    const isCurrentHour = (date, time) => {
        const [hour, minute] = time.split(':').map(Number);
        const slotDateTime = moment(date).tz(timeZone).hour(hour).minute(minute);
        return slotDateTime.isSame(moment().tz(timeZone), 'hour');
    };

    const isHourClickable = (date, time) => {
        const [hour, minute] = time.split(':').map(Number);
        const slotDateTime = moment(date).tz(timeZone).hour(hour).minute(minute);

        const currentHour = moment().tz(timeZone);
        const twoHoursAgo = currentHour.clone().subtract(2, 'hours');
        const oneHourAgo = currentHour.clone().subtract(1, 'hour');

        // Check if the slot is within two hours before the current hour or is the current hour
        if (
            slotDateTime.isSame(twoHoursAgo, 'hour') ||
            slotDateTime.isSame(oneHourAgo, 'hour') ||
            slotDateTime.isSame(currentHour, 'hour') // Include current hour as clickable
        ) {
            // Ensure the hour is not already completed
            return !isHourCompleted(date, time);
        }

        return false;
    };


    const handleHourClick = (date, time) => {
        const [hour, minute] = time.split(':').map(Number);
        const slotDateTime = moment(date).tz(timeZone).hour(hour).minute(minute).toISOString();
        setSelectedTime(slotDateTime);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setSelectedTime(null);
    };


    const handlePrevDay = () => {
        if (currentDayIndex > 0) setCurrentDayIndex((prev) => prev - 1);
    };

    const handleNextDay = () => {
        if (currentDayIndex < dailyTimeSlots.length - 1) setCurrentDayIndex((prev) => prev + 1);
    };


    if (!dailyTimeSlots.length) {
        return <Typography>{t('performanceChecklist.loading')}</Typography>;
    }

    const currentDay = dailyTimeSlots[currentDayIndex];

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <Button onClick={handlePrevDay} disabled={currentDayIndex === 0}>
                    {t('performanceChecklist.previousDay')}
                </Button>
                <Typography variant="h6">
                    {t('performanceChecklist.checklistTitle', { date: currentDay.date })}
                </Typography>
                <Button onClick={handleNextDay} disabled={currentDayIndex === dailyTimeSlots.length - 1}>
                    {t('performanceChecklist.nextDay')}
                </Button>
            </Box>

            <Grid container spacing={2}>
                {currentDay.timeSlots.map((time) => (
                    <Grid item xs={12} sm={4} key={time}>
                        <ListItem
                            sx={{
                                justifyContent: 'center',
                                backgroundColor: isCurrentHour(currentDay.date, time)
                                    ? 'lightblue'
                                    : isHourClickable(currentDay.date, time)
                                        ? 'lightgreen'
                                        : 'inherit',
                                borderRadius: '8px',
                                cursor: isHourClickable(currentDay.date, time) ? 'pointer' : 'default',
                            }}
                            onClick={() => {
                                if (isHourClickable(currentDay.date, time) || isCurrentHour(currentDay.date, time)) {
                                    handleHourClick(currentDay.date, time);
                                }
                            }}
                        >
                            <ListItemText
                                primary={`${time}`}
                                style={{
                                    textDecoration: isHourCompleted(currentDay.date, time)
                                        ? 'line-through'
                                        : 'none',
                                    textAlign: 'center',
                                }}
                            />
                        </ListItem>
                    </Grid>
                ))}
            </Grid>

            <Button variant="contained" onClick={onReturn} sx={{ marginTop: 2 }}>
                {t('performanceChecklist.return')}
            </Button>

            <Modal open={showModal} onClose={handleModalClose}>
                <Box sx={{ padding: 4, backgroundColor: 'white', borderRadius: 4, margin: 'auto', maxWidth: '400px' }}>
                    <PerformanceQuestion userId={userId} selectedTimestamp={selectedTime} onClose={handleModalClose} />
                </Box>
            </Modal>
        </Box>
    );
};

export default PerformanceChecklist;