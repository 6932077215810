import axios from 'axios';
import { toast } from 'react-toastify';
import CustomNotification from '../components/Notification/CustomNotification';

const API_URL = process.env.REACT_APP_API_URL + '/notifications';
const generateUniqueId = () => `notif-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

const loadAddedNotificationIds = () => {
    const savedIds = localStorage.getItem('addedNotificationIds');
    return savedIds ? new Set(JSON.parse(savedIds)) : new Set();
};

const saveAddedNotificationIds = (ids) => {
    localStorage.setItem('addedNotificationIds', JSON.stringify([...ids]));
    console.log(localStorage.getItem('addedNotificationIds'))
};

export const createNotification = async (notification) => {
    const response = await axios.post(API_URL, notification);
    return response.data;
};

export const getNotifications = async () => {
    return await axios.get(API_URL);
};

export const getNotificationsForUser = async (userId) => {
    return await axios.get(`${API_URL}/user/${userId}`);
};

export const acknowledgeNotification = async (notificationId) => {
    return await axios.delete(`${API_URL}/${notificationId}`);
};

export const getActiveNotificationsForUser = async (userId) => {
    return await axios.get(`${API_URL}/active/${userId}`);
};

export const markNotificationsAsSeen = async (notificationIds) => {
    return await axios.post(`${API_URL}/mark-seen`, { notificationIds });
};

export const addNotification = (notification, onAnswerQuestion) => {
    const { title, message, dateTime, type } = notification;
    const _id = notification._id || generateUniqueId();
    const timestamp = new Date(dateTime);
    const notificationKey = `${_id}-${type}`;

    const addedNotificationIds = loadAddedNotificationIds();
    if (addedNotificationIds.has(notificationKey)) {
        console.log('Duplicate notification detected:', notificationKey);
        return;
    }

    console.log('Adding notification:', { ...notification, _id }); // Log to ensure a unique ID is used

    toast(
        <CustomNotification
            title={title}
            message={message}
            timestamp={timestamp}
            onAnswerQuestion={() => onAnswerQuestion({ ...notification, _id })}
        />,
        {
            position: 'top-right',
            autoClose: false,
        }
    );

    addedNotificationIds.add(notificationKey);
    saveAddedNotificationIds(addedNotificationIds);
};

export const getScheduledNotificationForObservationPeriod = async (userId) => {
    try {
        const response = await axios.get(`${API_URL}/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching scheduled notifications for observation period:', error);
        throw error; // Rethrow to handle errors at the calling site if needed
    }
};
