import React, { useState, useEffect, useContext } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Drawer, List, ListItem, Link } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { fetchAvatar } from '../../services/userService';
import { UserContext } from '../../contexts/UserContext';
import defaultAvatar from '../../pic/avatar.png';
import './NavBar.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NavBar = ({ setDrawerOpen, drawerOpen, handleMenuItemClick, surveyCompleted }) => {
    const { user, logout } = useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [avatar, setAvatar] = useState(defaultAvatar);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (user && user._id) {
            loadAvatar(user._id);
        }
    }, [user]);

    const loadAvatar = async (userId) => {
        try {
            const base64Image = await fetchAvatar(userId);
            setAvatar(`data:image/jpeg;base64,${base64Image}`);
        } catch (error) {
            console.error('Failed to load avatar:', error);
        }
    };

    const handleMenuItemClickWrapper = (item) => {
        handleMenuItemClick(item);

        if (user.isAdmin) {
            navigate('/admin-dashboard');
        } else {
            navigate('/dashboard');
        }

        setDrawerOpen(false);
    };

    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language);
    };

    const renderMenuItems = () => {
        if (!surveyCompleted && !user?.isAdmin) {
            return (
                <>
                    <ListItem button onClick={() => handleMenuItemClickWrapper('surveyIntroduction')}>
                        <Typography variant="h6" style={{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '15px' }}>
                            {t('navbar.surveyIntroduction')}
                        </Typography>
                    </ListItem>
                    <ListItem button onClick={() => handleMenuItemClickWrapper('personalInfo')}>
                        <Typography variant="h6" style={{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '15px' }}>
                            {t('navbar.personalInfo')}
                        </Typography>
                    </ListItem>
                </>
            );
        }

        return (
            <>
                <ListItem button onClick={() => handleMenuItemClickWrapper('personalInfo')}>
                    <Typography variant="h6" style={{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '15px' }}>
                        {t('navbar.personalInfo')}
                    </Typography>
                </ListItem>
                {user?.isAdmin && (
                    <>
                        <ListItem button onClick={() => handleMenuItemClickWrapper('userList')}>
                            <Typography variant="h6" style={{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '15px' }}>
                                {t('navbar.userList')}
                            </Typography>
                        </ListItem>
                        <ListItem button onClick={() => handleMenuItemClickWrapper('setNotification')}>
                            <Typography variant="h6" style={{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '15px' }}>
                                {t('navbar.setNotification')}
                            </Typography>
                        </ListItem>

                        <ListItem button onClick={() => handleMenuItemClickWrapper('documentation')}>
                            <Typography variant="h6" style={{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '15px' }}>
                                {t('navbar.documentation')}
                            </Typography>
                        </ListItem>
                    </>
                )}
                {!user?.isAdmin && (
                    <>
                        <ListItem button onClick={() => handleMenuItemClickWrapper('overview')}>
                            <Typography variant="h6" style={{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '15px' }}>
                                {t('navbar.overview')}
                            </Typography>
                        </ListItem>
                        <ListItem button onClick={() => handleMenuItemClickWrapper('DailySurvey')}>
                            <Typography variant="h6" style={{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '15px' }}>
                                {t('navbar.dailySurvey')}
                            </Typography>
                        </ListItem>
                        <ListItem button onClick={() => handleMenuItemClickWrapper('performanceQuestion')}>
                            <Typography variant="h6" style={{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '15px' }}>
                                {t('navbar.performanceQuestion')}
                            </Typography>
                        </ListItem>
                        <ListItem button onClick={() => handleMenuItemClickWrapper('calendar')}>
                            <Typography variant="h6" style={{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '15px' }}>
                                {t('navbar.calendar')}
                            </Typography>
                        </ListItem>
                        <ListItem button onClick={() => handleMenuItemClickWrapper('result')}>
                            <Typography variant="h6" style={{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '15px' }}>
                                {t('navbar.result')}
                            </Typography>
                        </ListItem>
                    </>
                )}
            </>
        );
    };

    return (
        <>
            <AppBar position="fixed" className="navbar">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setDrawerOpen(true)}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" style={{ flexGrow: 1, fontFamily: 'Dosis, sans-serif' }}>
                        UFLOW
                    </Typography>
                    <div>
                        <Link href="/" className="navbar-link">{t('navbar.home')}</Link>
                    </div>
                    {user && (
                        <div
                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                        >
                            <img
                                src={avatar}
                                alt="User Avatar"
                                className="navbar-img"
                            />
                            <Typography variant="body1" style={{ marginLeft: '10px' }}>
                                {t('navbar.hi')}, {user.username}!
                            </Typography>
                            {/* Language Switcher */}
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                                <Typography
                                    variant="body2"
                                    onClick={() => handleLanguageChange('en')}
                                    style={{
                                        cursor: 'pointer',
                                        fontWeight: i18n.language === 'en' ? 'bold' : 'normal',
                                        textDecoration: i18n.language === 'en' ? 'underline' : 'none',
                                        marginRight: '10px'
                                    }}
                                >
                                    EN
                                </Typography>
                                <Typography
                                    variant="body2"
                                    onClick={() => handleLanguageChange('de')}
                                    style={{
                                        cursor: 'pointer',
                                        fontWeight: i18n.language === 'de' ? 'bold' : 'normal',
                                        textDecoration: i18n.language === 'de' ? 'underline' : 'none'
                                    }}
                                >
                                    DE
                                </Typography>
                            </div>
                        </div>
                    )}
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        <MenuItem onClick={logout}>{t('navbar.logout')}</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Drawer
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                PaperProps={{ style: { height: '100vh', zIndex: 1100 } }}
            >
                <div className="menu">
                    <List>
                        {renderMenuItems()}
                    </List>
                </div>
            </Drawer>
        </>
    );
};

export default NavBar;
