import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getPerformanceResponses } from '../../../../services/performanceService';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import './Calendar.css'; // Custom CSS file
import { useTranslation } from 'react-i18next';

const localizer = momentLocalizer(moment);

const CalendarComponent = ({ userId }) => {
    const [events, setEvents] = useState([]);
    const [originalEvents, setOriginalEvents] = useState([]); // Keep original events separate
    const [currentDate, setCurrentDate] = useState(new Date());
    const { t } = useTranslation();

    const getColorByScore = (score) => {
        switch (true) {
            case score >= 1 && score <= 2:
                return 'lightblue';
            case score >= 3 && score <= 4:
                return 'blue';
            case score >= 5 && score <= 6:
                return 'darkblue';
            case score >= 7 && score <= 10:
                return 'green';
            default:
                return 'gray';
        }
    };

    const roundToNearestHour = (date) => {
        const rounded = new Date(date);
        rounded.setMinutes(0, 0, 0); // Round down to the nearest hour
        return rounded;
    };

    // Function to fetch the responses and set the events
    const fetchResponses = async () => {
        try {
            const response = await getPerformanceResponses(userId);
            const eventsData = createEventsFromData(response.data); // Display only the fetched data
            setEvents(eventsData);
            setOriginalEvents(eventsData); // Keep original events in a separate state
        } catch (error) {
            console.error('Error fetching performance responses:', error);
        }
    };

    // Function to calculate averages when exporting the PDF
    const calculateAveragesForExport = (data) => {
        const eventsData = [];
        const existingEventsMap = {};
        const scoresByHour = {};
        let firstRecordDay = null;

        data.forEach((item, index) => {
            const start = roundToNearestHour(new Date(item.timestamp));
            const color = getColorByScore(item.score);

            const event = {
                id: String(index),
                title: `Score: ${item.score}`,
                start,
                end: new Date(start.getTime() + 3600000), // 1 hour event
                allDay: false,
                backgroundColor: color,
                style: {
                    backgroundColor: color,
                    borderColor: color,
                    color: 'white',
                },
                score: item.score,
                isAveraged: false
            };

            eventsData.push(event);
            existingEventsMap[start.getTime()] = event;

            const hourKey = start.getHours();
            if (!scoresByHour[hourKey]) {
                scoresByHour[hourKey] = [];
            }
            scoresByHour[hourKey].push(item.score);

            if (!firstRecordDay || start < firstRecordDay) {
                firstRecordDay = new Date(start.getFullYear(), start.getMonth(), start.getDate());
            }
        });

        const hoursInDay = Array.from({ length: 10 }, (_, i) => i + 8); // 8 AM to 5 PM
        const currentDate = moment().startOf('day').toDate(); // Current day

        let currentDay = firstRecordDay;
        while (currentDay <= currentDate) {
            const dayOfWeek = currentDay.getDay();

            if (dayOfWeek !== 6 && dayOfWeek !== 0 && currentDay < new Date()) {
                hoursInDay.forEach(hour => {
                    const eventTime = new Date(currentDay);
                    eventTime.setHours(hour);

                    if (!existingEventsMap[eventTime.getTime()] && scoresByHour[hour]?.length >= 2) {
                        const sum = scoresByHour[hour].reduce((acc, score) => acc + score, 0);
                        const averageScore = Math.floor(sum / scoresByHour[hour].length);
                        const color = getColorByScore(averageScore);

                        const averagedEvent = {
                            id: `averaged-${currentDay}-${hour}`,
                            title: `Score: ${averageScore} (Averaged)`,
                            start: eventTime,
                            end: new Date(eventTime.getTime() + 3600000), // 1 hour event
                            allDay: false,
                            backgroundColor: color,
                            style: {
                                backgroundColor: color,
                                borderColor: color,
                                color: 'white',
                            },
                            isAveraged: true,
                            score: averageScore
                        };

                        eventsData.push(averagedEvent);
                        existingEventsMap[eventTime.getTime()] = averagedEvent;
                    }
                });
            }
            currentDay = new Date(currentDay.getTime() + 86400000); // Move to the next day
        }

        return eventsData;
    };

    // Function to handle PDF export
    const handleExport = async () => {
        // Calculate averages and add them to the calendar state temporarily
        const response = await getPerformanceResponses(userId);
        const eventsDataForExport = calculateAveragesForExport(response.data);
        setEvents(eventsDataForExport); // Temporarily set both original and averaged events in the state

        // Wait for the UI to update
        setTimeout(async () => {
            const calendarElement = document.querySelector('.rbc-calendar'); // Grab the calendar element
            const canvas = await html2canvas(calendarElement);
            const imgData = canvas.toDataURL('image/png');

            const pdf = new jsPDF('landscape'); // Create a new jsPDF instance

            // First Page: Add the calendar screenshot
            pdf.addImage(imgData, 'PNG', 10, 10, 280, 150); // Add the screenshot to the PDF

            // Add a new page for event details
            pdf.addPage();
            pdf.setFontSize(14);
            pdf.text('Event Scores and Times', 10, 20); // Title for the new page
            pdf.setFontSize(12);

            let yPos = 30; // Declare yPos using 'let' so it can be reassigned later
            eventsDataForExport.forEach((event, index) => {
                // Format the start date and time for better readability
                const formattedDate = moment(event.start).format('YYYY-MM-DD HH:mm');

                // Construct the text to show in the PDF, including both score and the event's date/time
                const text = event.isAveraged
                    ? `Averaged Event ${index + 1}: Average Score ${event.score} (Time: ${formattedDate})`
                    : `Event ${index + 1}: Original Score ${event.score} (Time: ${formattedDate})`;

                // Calculate y-position dynamically based on the index to avoid text overlap
                if (yPos >= 280) { // If the text reaches the bottom of the page, add a new page
                    pdf.addPage();
                    yPos = 20; // Reset yPos for new page
                }
                pdf.text(10, yPos, text);
                yPos += 10; // Increment yPos for the next line
            });


            // Save the PDF
            pdf.save('calendar-screenshot.pdf'); // Save the PDF

            // Restore the original events after export
            setEvents(originalEvents);
        }, 100); // Small delay to ensure the UI updates
    };


    const createEventsFromData = (data) => {
        const eventsData = [];
        data.forEach((item, index) => {
            const start = roundToNearestHour(new Date(item.timestamp));
            const color = getColorByScore(item.score);

            const event = {
                id: String(index),
                title: `Score: ${item.score}`,
                start,
                end: new Date(start.getTime() + 3600000), // 1 hour event
                allDay: false,
                backgroundColor: color,
                style: {
                    backgroundColor: color,
                    borderColor: color,
                    color: 'white', // Text color for better readability
                },
                score: item.score,
            };

            eventsData.push(event);
        });

        return eventsData;
    };

    useEffect(() => {
        fetchResponses();
    }, [userId]);

    return (
        <div className="calendar-container">
            <div className="legend-card">
                <h3>{t('calendar.scoreLegend')}</h3>
                <ul>
                    <li><span className="legend-color" style={{ backgroundColor: 'lightblue' }}></span> {t('calendar.score1_2')}</li>
                    <li><span className="legend-color" style={{ backgroundColor: 'blue' }}></span> {t('calendar.score3_4')}</li>
                    <li><span className="legend-color" style={{ backgroundColor: 'darkblue' }}></span> {t('calendar.score5_6')}</li>
                    <li><span className="legend-color" style={{ backgroundColor: 'green' }}></span> {t('calendar.score7_10')}</li>
                    <li><span className="legend-color" style={{ backgroundColor: 'gray' }}></span> {t('calendar.noScore')}</li>
                </ul>
            </div>
            <button onClick={handleExport} className="export-button">Export</button>
            <div style={{ height: 'calc(100vh - 100px)', width: '100%' }}>
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: '100%', width: '100%' }}
                    eventPropGetter={(event) => ({
                        style: event.style,
                    })}
                    views={['week']}
                    defaultView="week"
                    date={currentDate} // Controls the current date shown on the calendar
                    onNavigate={date => setCurrentDate(date)} // Syncs internal state with calendar navigation
                    min={new Date(1970, 1, 1, 8, 0, 0)} // Set the minimum time to 8:00 AM
                    max={new Date(1970, 1, 1, 19, 0, 0)} // Set the maximum time to 7:00 PM
                />
            </div>
        </div>
    );
};

export default CalendarComponent;
