import React, { useState, useEffect } from 'react';
import { getPerformanceResponses } from '../../../../services/performanceService';
import { getScheduledNotificationForObservationPeriod } from '../../../../services/notificationsService';
import { fetchQ20Data } from '../../../../services/surveyService';
import { Box, Typography, Select, MenuItem, FormControl, InputLabel, Button, IconButton } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { addDays, format, isWithinInterval, getDay } from 'date-fns';
import { ArrowBack } from '@mui/icons-material';

const SurveyProgressBar = ({ userId, onReturn }) => {
    const [scheduledNotifications, setScheduledNotifications] = useState([]);
    const [performanceData, setPerformanceData] = useState([]);
    const [q20Data, setQ20Data] = useState({});
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [chartsData, setChartsData] = useState([]);
    const [currentChartIndex, setCurrentChartIndex] = useState(0);
    const timeZone = 'Europe/Berlin';

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [notificationsResponse, performanceResponses, surveyData] = await Promise.all([
                    getScheduledNotificationForObservationPeriod(userId),
                    getPerformanceResponses(userId),
                    fetchQ20Data(userId)
                ]);

                setScheduledNotifications(notificationsResponse.dateRanges);
                setPerformanceData(performanceResponses.data);
                setQ20Data(surveyData);

                // Set the first observation period as the default selected period
                if (notificationsResponse.dateRanges.length > 0) {
                    setSelectedPeriod({
                        ...notificationsResponse.dateRanges[0],
                        label: `Period 1: ${format(new Date(notificationsResponse.dateRanges[0].startDate), 'yyyy-MM-dd')}`
                    });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [userId]);

    useEffect(() => {
        if (selectedPeriod && q20Data.startTime && q20Data.duration) {
            generateChartsData();
        }
    }, [selectedPeriod, q20Data]);

    const generateChartsData = () => {
        const startTime = q20Data.startTime || '09:00';
        const duration = q20Data.duration || 6;
        const [startHour] = startTime.split(':').map(Number);

        // Calculate time slots for the x-axis
        const timeSlots = Array.from({ length: duration }, (_, i) => `${startHour + i}:00`);

        // Initialize progress data for each weekday
        const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
        const progressData = weekdays.map(() => Array(duration).fill(0));

        // Filter performance responses within the selected observation period
        const startDate = new Date(selectedPeriod.startDate);
        const endDate = new Date(selectedPeriod.endDate);

        const filteredPerformanceData = performanceData.filter(entry => {
            const entryDate = new Date(entry.timestamp);
            return isWithinInterval(entryDate, { start: startDate, end: endDate });
        });

        // Map performance responses to weekdays and times
        filteredPerformanceData.forEach(entry => {
            const entryDate = new Date(entry.timestamp);
            const weekdayIndex = getDay(entryDate) - 1; // getDay returns 0 for Sunday, adjust for Monday to Friday

            if (weekdayIndex >= 0 && weekdayIndex < 5) { // Check for weekdays only
                const entryHour = entryDate.getHours();
                const timeSlotIndex = entryHour - startHour;

                if (timeSlotIndex >= 0 && timeSlotIndex < duration) {
                    progressData[weekdayIndex][timeSlotIndex] += 1;
                }
            }
        });

        // Convert counts to percentages
        const chartsData = weekdays.map((weekday, index) => ({
            labels: timeSlots,
            datasets: [
                {
                    label: `${weekday} Progress`,
                    data: progressData[index].map(count => (count >= 2 ? 100 : count === 1 ? 50 : 0)),
                    backgroundColor: 'rgba(54, 162, 235, 0.6)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                }
            ]
        }));

        setChartsData(chartsData);
        setCurrentChartIndex(0); // Reset to the first chart when generating new data
    };

    const handlePeriodChange = (event) => {
        const selected = scheduledNotifications.find(
            range => range.startDate === event.target.value
        );
        setSelectedPeriod({
            ...selected,
            label: `Period ${scheduledNotifications.indexOf(selected) + 1}: ${format(new Date(selected.startDate), 'yyyy-MM-dd')}`
        });
    };

    const handlePrevious = () => {
        setCurrentChartIndex(prevIndex => Math.max(prevIndex - 1, 0));
    };

    const handleNext = () => {
        setCurrentChartIndex(prevIndex => Math.min(prevIndex + 1, chartsData.length - 1));
    };

    return (
        <Box sx={{ padding: 4 }}>
            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                <IconButton onClick={onReturn}>
                    <ArrowBack />
                </IconButton>
                <Typography variant="h6" sx={{ ml: 2 }}>
                    Performance Question Progress
                </Typography>
            </Box>
            <FormControl fullWidth sx={{ mb: 4 }}>
                <InputLabel>Select Observation Period</InputLabel>
                <Select
                    value={selectedPeriod?.startDate || ''}
                    onChange={handlePeriodChange}
                >
                    {scheduledNotifications.map((range, index) => (
                        <MenuItem key={index} value={range.startDate}>
                            {`Period ${index + 1}: ${format(new Date(range.startDate), 'yyyy-MM-dd')}`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {selectedPeriod && (
                <Box sx={{ mb: 2 }}>
                    <Typography variant="body1">
                        Selected Observation Period: {format(new Date(selectedPeriod.startDate), 'yyyy-MM-dd')}
                    </Typography>
                </Box>
            )}

            {chartsData.length > 0 && (
                <Box sx={{ mb: 4 }}>
                    <Bar
                        data={chartsData[currentChartIndex]}
                        options={{
                            scales: {
                                x: { title: { display: true, text: 'Time Slot' } },
                                y: { title: { display: true, text: 'Progress (%)' }, min: 0, max: 100 }
                            }
                        }}
                    />
                </Box>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    variant="contained"
                    onClick={handlePrevious}
                    disabled={currentChartIndex === 0}
                >
                    Previous
                </Button>
                <Button
                    variant="contained"
                    onClick={handleNext}
                    disabled={currentChartIndex === chartsData.length - 1}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
};

export default SurveyProgressBar;
