import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Q4 = ({ userId, onNext, onPrevious, saveResponse }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    const options = [
        { value: 0, label: 'Invisible Option' },  // Invisible option
        { value: 1, label: t('questions.q4.options.notAtAllEasy') },
        { value: 2, label: t('questions.q4.options.notVeryEasy') },
        { value: 3, label: t('questions.q4.options.quiteEasy') },
        { value: 4, label: t('questions.q4.options.veryEasy') }
    ];

    useEffect(() => {
        const fetchExistingResponse = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                if (!user || !user.token) {
                    throw new Error('No token found in localStorage');
                }
                const token = user.token;
                const baseUrl = process.env.REACT_APP_API_URL;

                const response = await axios.get(`${baseUrl}/survey/get-response/q4/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (response.data && response.data.score) {
                    setSelectedOption(response.data);
                }
            } catch (error) {
                console.error('Error fetching existing response:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchExistingResponse();
    }, [userId]);

    const handleOptionChange = (e) => {
        const selectedValue = parseInt(e.target.value);
        const selectedLabel = options.find(option => option.value === selectedValue).label;
        setSelectedOption({ score: selectedValue, description: selectedLabel });
    };

    const handleNext = () => {
        if (selectedOption?.score !== 0) {  // Prevent saving the invisible option
            saveResponse('q4', selectedOption);
            onNext();
        }
    };

    if (loading) {
        return <p>{t('questions.q4.loading')}</p>;
    }

    return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
            <h3>{t('questions.q4.title')}</h3>
            <form>
                {options.map(option => (
                    <div key={option.value} style={option.value === 0 ? { color: 'transparent' } : {}}>
                        <input
                            type="radio"
                            id={`option${option.value}`}
                            name="q4"
                            value={option.value}
                            checked={selectedOption && selectedOption.score === option.value}
                            onChange={handleOptionChange}
                            style={option.value === 0 ? { opacity: 0 } : {}}
                        />
                        <label htmlFor={`option${option.value}`}>{option.label} ({option.value})</label>
                    </div>
                ))}
            </form>
            <button onClick={onPrevious} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}>{t('questions.q4.previous')}</button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button onClick={handleNext} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }} disabled={!selectedOption || selectedOption.score === 0}>{t('questions.q4.next')}</button>
        </div>
    );
};

export default Q4;
