import React from 'react';
import './EditModal.css';

const EditModal = ({ isOpen, data, onClose, onSave }) => {
    // Use optional chaining and default values to avoid errors
    const [wakeUpTime, setWakeUpTime] = React.useState(data?.wakeUpTimeOriginal || '');
    const [bedTime, setBedTime] = React.useState(data?.bedTimeOriginal || '');
    const [sleepQuality, setSleepQuality] = React.useState(data?.sleepQuality || 5);

    React.useEffect(() => {
        // Update state when `data` changes
        if (data) {
            setWakeUpTime(data.wakeUpTimeOriginal || '');
            setBedTime(data.bedTimeOriginal || '');
            setSleepQuality(data.sleepQuality || 5);
        }
    }, [data]);

    const handleSave = () => {
        if (data) {
            onSave({ ...data, wakeUpTime, bedTime, sleepQuality });
        }
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h4>Edit Data for {data?.date || 'N/A'}</h4>
                <div>
                    <label>Wake Up Time:</label>
                    <input
                        type="time"
                        value={wakeUpTime}
                        onChange={(e) => setWakeUpTime(e.target.value)}
                    />
                </div>
                <div>
                    <label>Bed Time:</label>
                    <input
                        type="time"
                        value={bedTime}
                        onChange={(e) => setBedTime(e.target.value)}
                    />
                </div>
                <div>
                    <label>Sleep Quality:</label>
                    <input
                        type="range"
                        min="1"
                        max="10"
                        value={sleepQuality}
                        onChange={(e) => setSleepQuality(Number(e.target.value))}
                    />
                    <span>{sleepQuality}</span>
                </div>
                <button onClick={handleSave}>Save</button>
                <button onClick={onClose}>Cancel</button>
            </div>
        </div>
    );
};

export default EditModal;
